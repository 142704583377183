import React, { useEffect, useState } from "react";
import OrderRecived from "../../src/components/OrderRecived/OrderRecived";
import { useParams } from "react-router-dom";
import { getOrderDetailsById } from "../services/operations/orderApi";
import { useDispatch, useSelector } from "react-redux";
import { getCartItemsByUserAndStatus } from "../services/operations/cartItemApi";
import { setCartData, setCartCount } from "../redux/slices/cartSlice";
const OrderRecivedPage = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { orderId } = useParams();
  const [orderLoading, setOrderLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [cartLoading, setCartLoading] = useState(false);

 

  useEffect(() => {
    setOrderLoading(true);
    const fetchOrderDetails = async () => {
      const response = await getOrderDetailsById(orderId, token);
  
      setOrderDetails(response);
    };
    fetchOrderDetails();
    setOrderLoading(false);
  }, [token]);

  const fetchCartItemsData = async () => {
    try {
      setCartLoading(true);
      const response = await getCartItemsByUserAndStatus(token);
      dispatch(setCartData(response));
      dispatch(setCartCount(response.length));
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  useEffect(() => {
    fetchCartItemsData();
  }, [token, dispatch, orderId]);

  return (
    <div>
      <OrderRecived orderLoading = {orderLoading} orderDetails={orderDetails} />
    </div>
  );
};

export default OrderRecivedPage;
