import React from 'react'
import MyAccountForm from '../components/UserAccount/MyAccountForm'
const UserAccountPage = ({setIsEditProfile,onProfileUpdate}) => {
  return (
    <div className="w-full h-[410px]">
      <MyAccountForm setIsEditProfile={setIsEditProfile} onProfileUpdate={onProfileUpdate}/>
    </div>
  )
}

export default UserAccountPage
