import React from 'react'
import FrequentlyAskedQuestions from '../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'

const FAQ = () => {
  return (
    <div>
      <FrequentlyAskedQuestions />
    </div>
  )
}

export default FAQ

