import React, { useEffect, useState } from "react";
import { LuSparkle } from "react-icons/lu";
import {mdCurrencyRupee } from "react-icons/md";
import { IoBag } from "react-icons/io5";
import { Card } from "../../ui/card";
import { SkeletonCard } from "../../Skeletoncard";
import Buyer1 from '../../../images/Downbanner.png'
import Buyer2 from '../../../images/Downbanner2.png'
import Buyer3 from '../../../images/Downbanner3.png'
import { getAllProducts } from "../../../services/operations/productApi";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import { Button } from "../../ui/button";
import { Link, useNavigate } from "react-router-dom";
import {
  addItemToCart,
  decrementCartItem,
  getCartItemsByUserAndStatus,
  getCartItemsByUserAndStatusAndProducts,
  incrementCartItem,
} from "../../../services/operations/cartItemApi";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { setCartCount, setCartData } from "../../../redux/slices/cartSlice";
import Buyer4 from '../../../images/Downbanner4.png'
const ExploreNew = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [products, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noToken, setNoToken] = useState(false)
  const navigate = useNavigate();
  const [cartLoading, setCartLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [sizeId, setSizeId] = useState(null);
  const [colorId, setColorId] = useState(null);
  const staticImages=[{url:Buyer1,
    category:'Bags',
    id:2

  },{
    url:Buyer2,
    category:'Wallets',
    id:18
  },{
    url:Buyer4,
    category:'Chappals',
    id:7

  }]

  const fetAllProducts = async () => {
    try {
      setLoading(true);
      const response = await getAllProducts();
    
      setAllProducts(response.slice(13, 16));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  
  const handleSubCategoryProducts = (id,index) => {
    if(index==0){
     
      navigate('/shop/products', { state: {id:id } });
    }else {
  
    navigate('/shop/products', { state: {subSubCategoryId: id } });
    }

   
  };

  useEffect(() => {
    fetAllProducts();
  }, []);
  const handleCart = (productId, sizeId, colorId) => {

    if (!token) {
      setNoToken(true)

      localStorage.setItem('selectedSize', sizeId);
      localStorage.setItem('selectedProduct', productId);
      localStorage.setItem('selectedColor', colorId);
      document.cookie = "cart_visited=true; path=/";

    } else {
      addToCart(productId, sizeId, colorId)
    }

  }

  const addToCart = async (productId, sizeId, colorId) => {
    if (!sizeId || !colorId) {
      toast.error("Please Select Size and color");
      return;
    }
    if (!sizeId) {
      toast.error("Please Select Size");
      return;
    }
    if (!colorId) {
      toast.error("Please Select Color");
      return;
    }
    try {
      setCartLoading(true);
      const response = await addItemToCart(productId, sizeId, colorId, token);
      const response1 = await getCartItemsByUserAndStatusAndProducts(token);
     
      dispatch(setCartData(response1));
      dispatch(setCartCount(response1?.length));
      setCartItems((prevItems) => {
        if (!Array.isArray(prevItems)) {
          return [response];
        }
        const existingItem = prevItems.find(
          (item) => item?.productId === productId
        );
        if (existingItem) {
          return prevItems.map((item) =>
            item?.productId === productId ? response : item
          );
        } else {
          return [...prevItems, response];
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  const increaseQuantity = async (cartItemId) => {
    try {
      setCartLoading(true);
      const response = await incrementCartItem(cartItemId, token);
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.cartItemId === cartItemId ? response : item
        )
      );
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  const decreaseQuantity = async (cartItemId) => {
    try {
      setCartLoading(true);
      const response = await decrementCartItem(cartItemId, token);
      if (response.quantity === 1) {
        window.location.reload();
      } else {
        // Update the cart item normally
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.cartItemId === cartItemId ? response : item
          )
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCartLoading(true);
        const response = await getCartItemsByUserAndStatus(token);
        setCartItems(response);
      } catch (error) {
        console.log(error);
      } finally {
        setCartLoading(false);
      }
    };
    fetchData();
  }, [token]);



  return (
    <div className="max-w-[1920px] mx-auto h-30 sm:px-[5rem] sm:py-2">
       
       <h1 className="font-orator text-[#6C3516] items-center text-4xl 1441px:mt-3 leading-60 mt-4 mb-5  flex justify-center">
    <p className="font-orator text-[40px] mb-1 ">O</p>
    <p className="font-orator space-x-0 tracking-tighter text-[33px] mr-2 ">UR BEST SELLERS</p>
  </h1>
          

       
        <div className="flex gap-5 flex-col items-center sm:items-center lg:flex-wrap lg:items-start  lg:flex-row">
          {loading || products.length < 1
            ? Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="h-[270px] w-[289px] p-3 ml-1 px-4 rounded md:rounded-none"
              >
                <SkeletonCard
                  isAllProducts={false}
                  circle={false}
                  height="h-[490px] 1441px:h-[500px]"
                  width="w-[240px] 1441px:w-[300px]"
                  key={index}
                  className="w-[220px] flex flex-1 h-[150px] rounded-sm bg-[#ebc4a3]"
                />
              </div>
            ))
            : staticImages.map((item,index)=>(

          
              
                
                <div
                onClick={()=>{handleSubCategoryProducts(item.id,index)
                  window.scrollTo({ top: 0, behavior: "smooth" })
                }}
                  key={index}
                  className="sm:w-[420px] relative    sm:h-[500px] xl:mb-12 flex flex-col items-center sm:items-start  sm:flex-1 p-0  w-[320px] "
                >
           
                    <img
                     
                      src={item.url}
                      alt="categoryImage"
                      className="w-[100%] h-[100%] rounded-xl lg:rounded-none  object-fit mx-auto cursor-pointer"
                    />
                   <p className="text-[#303030] w-full  h-[50px] sm:h-[0px] mt-2 text-[18px] font-orator font-[500] text-center ">
                  {item.category}
                  </p>
               
                </div>
               ))
          }
        </div>
  
    </div>
  );
};

export default ExploreNew;
