import axios from "axios";
import {
  getUserDetailsByVerifyToken,
  verifyUser,
} from "../../../services/operations/authApi";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setToken, setUser } from "../../../redux/slices/authSlice";
import { BASE_URL, endpoints } from "../../../services/api";
import toast from "react-hot-toast";
import { Separator } from "../../ui/separator";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const VerifiyAccount = () => {
  const dispatch = useDispatch();
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();

  const { verifyToken } = useParams();


  useEffect(() => {
    setLoading(true);
    const fetchUserDetialsByVerifyToken = async () => {
      const response = await getUserDetailsByVerifyToken(verifyToken);
      setEmailId(response);
    };
    fetchUserDetialsByVerifyToken();
  }, [verifyToken]);

  const handleVerifyUser = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(`${BASE_URL}/verifyUser`, {
        emailId: emailId,
        password: password,
        token: verifyToken,
      });
      toast.success("Email Verified!. Login Successful");

      localStorage.setItem("token", JSON.stringify(response.data.accessToken));
      localStorage.setItem("userData", JSON.stringify(response.data));
      dispatch(setToken(response.data.accessToken));
      dispatch(setUser(response.data));
      navigate("/");
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Invaild Password")
      setLoading(false);
    }
  };


  return (
    <div className="mx-auto max-w-lg w-[500px] mb-3 mt-3 p-3">
      <div className="bg-white border border-gray-300 rounded-lg  px-6  py-3 mt-5 mx-auto inline-block w-full">
        <div className="w-full mx-auto mt-2">
          <form onSubmit={handleVerifyUser}>
            <div className="flex flex-col justify-center pb-3">
              <h1 className="flex items-center justify-center text-[20px] font-bold">Verify User</h1>
              <Separator className="mt-2 mb-2" />
              <label className="font-semibold text-base text-gray-700 mb-4">
                Email
              </label>
              <input
                type="text"
                value={emailId}
                disabled
                className="rounded-[7px] w-full  border p-2 placeholder-gray-400 pr-10 pl-4"
                placeholder="Email address or username"
              />
            </div>

            <div className="flex relative flex-col justify-center pb-3">
              <label className="font-semibold text-base text-gray-700 mb-4">
                Password <span className="text-red-500">*</span>
              </label>
              <div className=" w-full relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder={"Enter Password"}
                  value={password}
                  onChange={(e) =>
                    setPassword(e.target.value)
                  }
                  className={`rounded-[7px] w-full  border p-2 placeholder-gray-400 pr-10 pl-4`}
                />

                <div className="absolute right-3 top-5 transform -translate-y-1/2 cursor-pointer">
                  {showPassword ? (
                    <FaEyeSlash
                      onClick={() => setShowPassword(false)}
                      className="text-gray-400 "
                    />
                  ) : (
                    <FaEye
                      onClick={() => setShowPassword(true)}
                      className="text-gray-400"
                    />
                  )}
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="bg-[#552038] hover:bg-[#411b2d]  w-full px-2 py-2 rounded-[15px] mt-2 text-white "
            >
              Verify
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifiyAccount;
