import toast from "react-hot-toast";
import { cartItemEndPoints } from "../api";
import { apiConnector } from "../apiConnector";

export const addItemToCart = async (productId, sizeId, colorId, token) => {
  let result;
  try {
    const response = await apiConnector(
      "POST",
      cartItemEndPoints.ADD_ITEM_TO_CART,
      { productId, sizeId, colorId },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    result = response.data;
    toast.success("Item Added to The Cart");
    return result;
  } catch (error) {
    console.log(error);
    // toast.error(error?.response?.data?.message);
  }
};

export const getCartItemsByUserAndStatus = async (token) => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      cartItemEndPoints.GET_ALL_CART_ITEMS,
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getCartItemsByUserAndStatusAndProducts = async (token) => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      cartItemEndPoints.GET_ALL_CART_ITEMS_WITH_PRODUCTS,
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const incrementCartItem = async (cartItemId, token) => {
  let result;
  try {
    const response = await apiConnector(
      "PUT",
      cartItemEndPoints.INCREAMENTCARTITEM,
      { cartItemId },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    result = response.data;
    setTimeout(()=>{
      toast.success("Quantity Increased By 1");
    },3000)
 
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const decrementCartItem = async (cartItemId, token) => {
  let result;
  try {
    const response = await apiConnector(
      "PUT",
      cartItemEndPoints.DECREMENT_CART_ITEM,
      { cartItemId },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    result = response.data;
    setTimeout(()=>{
      toast.success("Quantity Decreased By 1");
    },3000)
 
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const removeItemFromCart = async (token, cartItemId) => {
  let result;
  try {
    const response = await apiConnector(
      "DELETE",
      cartItemEndPoints.REMOVE_ITEM_FROM_CART,
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      { cartItemId: cartItemId }
    );
    result = response.data;
    toast.error(response.data?.message);
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const updateCartItemBySize = async (token, cartItemId,sizeId) => {
  let result;
  try {
    const response = await apiConnector(
      "PUT",
      cartItemEndPoints.UPDATE_CART_ITEM_BY_SIZE,
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      { cartItemId: cartItemId ,
        sizeId:sizeId
      }
    );
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const updateCartItemByColor = async (token, cartItemId,colorId) => {
  let result;
  try {
    const response = await apiConnector(
      "PUT",
      cartItemEndPoints.UPDATE_CART_ITEM_BY_COLOR,
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      { cartItemId: cartItemId ,
        colorId:colorId
      }
    );
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};
