import React from 'react'
import Login from '../components/auth/Login/Login'
import Navbar from './Navbar'
import TopBar from '../components/Home/TopBar'

const LoginPage = () => {
  return (
    <div>
      <Login />
    </div>
  )
}

export default LoginPage