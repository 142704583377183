import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAllAddress,
  removeAddress,
} from "../../services/operations/addressApi";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import toast from "react-hot-toast";
import { Button } from "../ui/button";
import AddressModel from "../models/AddressModel";
import noAddress from "../../images/no-saved-address.png";
import { Card } from "../ui/card";
import { getUserById } from "../../services/operations/authApi";
import Loading from "../Loader/Loading";

const AllAddress = () => {
  const [allAddressData, setAllAddressData] = useState([]);
  const [hideRemoveBtn, setHideRemoveBtn] = useState(false);
  const [newUser, setNewUser] = useState([]);
  const { token, user } = useSelector((state) => state.auth);
  const userId = user?.userId;
  const [userData, setUserData] = useState(null);

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    fetchData();
    fetchUser();
  }, []);

  const fetchUser = async (userId) => {
    const response = await getUserById(userId);
  
    setUserData(response);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getAllAddress(userId, token);
      if (response?.length == 1) {
        setHideRemoveBtn(true);
      }
      setAllAddressData(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteAddress = async (addressId) => {
    try {
      
      await removeAddress(addressId, token);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center mt-40">
          <Loading />
        </div>
      ) : (
        <>
          {allAddressData.length !== 0 && (
            <div className="flex justify-end w-1920 sm:px-[5rem] p-3 sm:p-0 py-2">
              <button className="bg-[#56243B] flex text-white sm:px-3 py-2 rounded-lg mt-5 justify-end">
                <AddressModel
                  editTag={"Add New Address"}
                  allAddressData={allAddressData}
                  setAllAddressData={setAllAddressData}
                />
              </button>
            </div>
          )}
          <div className="w-full h-full justify-start">
            {allAddressData?.length === 0 ? (
              <div className="flex justify-center items-center flex-col h-[300px]">
                <img
                  src={noAddress}
                  alt="noAddress"
                  className="w-[10rem] h-[10rem]"
                />
                <p className="font-bold text-base font-basicommercial  text-[#000000]">
                  Add Your Address Now
                </p>
                <p className="text-base text-[#000000] font-basicommercial ">
                  Add your home and office addresses and enjoy faster checkout
                </p>
                <button className="bg-[#56243B] text-white px-3 py-2 rounded-lg mt-5">
                  <AddressModel
                    editTag={"Add New Address"}
                    allAddressData={allAddressData}
                    setAllAddressData={setAllAddressData}
                  />
                </button>
              </div>
            ) : (
              <div className="flex flex-wrap items-center h-fit w-1920 sm:px-[5rem] py-2 gap-x-12">
                {/* <div className="flex gap-9 items-center bg-[#D9D9D9] px-2 py-2 rounded">
                <p className="font-prompt text-[14px] text-[#000000]">
                  <AddressModel
                    editTag={"Add New Address"}
                    allAddressData={allAddressData}
                    setAllAddressData={setAllAddressData}
                  />
                </p>
              </div> */}
                {allAddressData?.map((address, index) => (
                  <div
                    key={index}
                    className="flex flex-wrap gap-9 items-center p-2"
                  >
                    <Card className="border border-[#D9D9D9] w-[350px] h-fit py-4 ">
                      <div className="flex justify-start px-10 items-center gap-6 p-3">
                        <div className="font-basicommercial text-lg  font-bold text-[16px]">
                          {user?.firstName + " " + user?.lastName}
                        </div>
                        {/* <div className="text-[#03A685] border border-[#03A685] w-[75px] h-[25px] px-1 py-3 rounded-full flex justify-center items-center font-prompt">
                      Home
                    </div> */}
                      </div>
                      <div className="h-[30px]">
                        {(address?.street ||
                          address?.landmark ||
                          address?.city ||
                          address?.state) && (
                          <p className="text-[#6C6C6C] font-basicommercial text-lg text-[15px] px-10">
                            {`${address?.street}, ${address?.landmark}, ${address?.city}, ${address?.state}`}
                          </p>
                        )}
                      </div>

                      <p className="font-basicommercial text-lg  text-[#6C6C6C] px-10 mt-5">
                        Mobile
                        <span className="font-bold font-basicommercial  ml-2">
                          {user?.mobileNumber}
                        </span>
                      </p>
                      <div className="flex px-10 gap-2 mt-10">
                        <button>
                          <AlertDialog>
                            <AlertDialogTrigger asChild>
                              <Button variant="destructive">Remove</Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>
                                  Are you absolutely sure?
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                  This action cannot be undone. This will
                                  permanently delete your Address and remove
                                  your data from our servers.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={() => {
                                    handleDeleteAddress(address?.addressId);
                                  }}
                                >
                                  Continue
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        </button>
                        <button className="font-prompt w-[100px] text-[18px] text-[#000000] rounded border border-[#4A4848]">
                          <AddressModel
                            editTag={"Edit"}
                            addressId={address?.addressId}
                            setAllAddressData={setAllAddressData}
                          />
                        </button>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AllAddress;
