import React, { useState, useEffect } from "react";
import { RiArrowUpSLine } from "react-icons/ri";
import { CiUndo } from "react-icons/ci";
import { LiaUndoAltSolid } from "react-icons/lia";
import { Checkbox } from "../../components/ui/checkbox";
import { MdCurrencyRupee } from "react-icons/md";
import SingleProduct from "../Home/Products/SingleProduct";
import { Link } from "react-router-dom";
import { Label } from "../ui/label";
import { IoIosClose } from "react-icons/io";
import heartIcon from "../../images/heart.png"
import colorHeart from "../../images/colorHeart.png"
import { Range, getTrackBackground } from "react-range";
import { useNavigate } from "react-router-dom";
import { RiArrowDownSLine } from 'react-icons/ri';

import { IoMdClose } from "react-icons/io";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import {
  getAllProducts,
  getProductBySubCategoryId,
  getProductByMultipleCategoryId,
  getALLProductBySubCategoryId,
  getALLProductByCategoryId,
  getALLProductBySubSubCategoryId,
  getMenAllProducts,
  getWomenAllProducts,
  getUniSexAllProducts,
  GetAllSpecialProducts
} from "../../services/operations/productApi";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { useSelector } from "react-redux";
import LoadingPage from "../../pages/LoadingPage";
import { getProductByCategoryId } from "../../services/operations/productApi";
import { getCategoryById } from "../../services/operations/cartegoryApi";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../ui/pagination";
import { Input } from "../ui/input";
import toast from "react-hot-toast";
import { FaFilter } from "react-icons/fa";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Button } from "../ui/button";
import { GrPowerReset } from "react-icons/gr";


const AllFilteredProducts = ({
  colorsData,
  categoriesData,
  productsData,
  loading,
  setProductsData,
  cid,
  subSubCategoryId,
  setLoading,
  subCategoryId,
  categoryLoading,
  subCategoryLoading,
  productLoading,
  setCategoryLoading,
  setSubCategoryLoading,
  setProductLoading,
  setColorLoading,
  colorLoading,
  menId,
  womenId,
  unisexId,
  specialPrice

}) => {
  const { token } = useSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [minPrice, setMinPrice] = useState("0");
  const [maxPrice, setMaxPrice] = useState("11000");
  const [appliedFilter, setAppliedFilter] = useState(false);
  const [sortedGoods, setSortedGoods] = useState();
  const [selectedValue, setSelectedValue] = useState("");
  const [key, setKey] = useState(+new Date());
  const [dateKey, setDateKey] = useState(+new Date() + 1);
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([])
  const [sortedOrder, setSortOrder] = useState("");
  const [colorId, setColorId] = useState(null);
  const [initialProductsData, setInitialProductsData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedDateValue, setSelectedDatevalue] = useState("");
  const [exception, setException] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedColor, setSelectedColor] = useState(null);
  const [isColorDropdownOpen, setIsColorDropdownOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showAllColor, setShowAllColor] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isPriceOpen, setIsPriceOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedSort, setSelectedSort] = useState('');
  const STEP = 50;
  const MIN = 0;
  const MAX = 0;

  const fetchData = async () => {
    try {
      setProductLoading(true);
      const response = await getAllProducts();
      setProductLoading(false);
      return response;
    } catch (error) {
      console.log(error);
      setProductLoading(false);
    }
  };
  useEffect(()=>{
    if(cid){
    
    setSelectedCategories(cid);
      
    }
  },[])
  const navigate=useNavigate()
  const toggleAccordion = () => setIsOpen(!isOpen);
  const toggleAccordionForPrice = () => setIsPriceOpen(!isPriceOpen);

  const handleDateSelect = (value) => {
    setCurrentPage(1)
    setSelectedDate(value);
    setIsOpen(false);

    handleDate(value);
  };
  // const handlePriceSelect = (value) => {
  //   setSelectedDate(value);
  //   setIsPriceOpen(false);

  //   handleDate(value);
  // };
  const handlePriceSelect = (value) => {
    setSelectedSort(value);
    setIsPriceOpen(false);
    setCurrentPage(1)
    handleSort(value);
  };
  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };
  const toggleColorShowAll = () => {
    setShowAllColor((prevShowAll) => !prevShowAll);
  };
  const handleCategoryChange = async (value) => {
    
    setAppliedFilter(true);
    setCurrentPage(1)
    if (value.length == 0) {
      handleResetFilter()
    }
    const categoryData = await getCategoryById(value, token);

    const updatedCategories = selectedCategories.includes(value)
      ? selectedCategories.filter(category => category !== value)
      : [...selectedCategories, value];
     
    setSelectedCategories(updatedCategories);


    if (updatedCategories.includes(value)) {
      addCategoryFilter(categoryData?.categoryName,categoryData.categoryId);
    } else {
      removeCategoryFilter(categoryData?.categoryName,value);
    }

    
    
    await fetchCategoryProducts(updatedCategories);
  };


  const removeCategoryFilter = async(category) => {
  
   
    setSelectedFilters((prevFilters) =>
      prevFilters.filter((filter) => filter.category !== category)
    );
    
  
  };



  const toggleColorDropdown = () => {
    setIsColorDropdownOpen(prevState => !prevState);
  };

  const handleColor = (colorId) => {
    const selectedColorItem = colorToShow.find(item => item.colorId === colorId);
    setSelectedColor(selectedColorItem?.colorName);
    const filteredProductsColor = productsData?.filter((product) =>
      product.colors.some((color) => color.colorId == colorId)
    );
    setCurrentPage(1)
    setProductsData(filteredProductsColor);
    
  };


  const fetchCategoryProducts = async (categories) => {
    try {
    
     if(categories.length==0){
 
      setProductsData(initialProductsData)
    
     }else{
      
    
      const categoryProducts = await getProductByMultipleCategoryId(categories, token);
     console.log(categoryProducts,'bat')
     
      if (categoryProducts.length > 0) {
        setAppliedFilter(true)
        setProductsData(categoryProducts);

      } else {
        console.log('else')
        setProductsData([]);
        toast.error('No products found in this category');
      }

      setCategoryLoading(false);
     }

   

     
    } catch (err) {
      
      setLoading(false);
      setCategoryLoading(false);
      setColorLoading(false);

      if (err?.response?.data?.message) {
        toast.error('No products found in this category');
      } else {
        toast.error('An error occurred while fetching products');
      }
    }
  };

  const totalItemsPerPage = 9;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const addCategoryFilter = (category, categoryId) => {
   
console.log(category,categoryId,'oos')
  
    const alreadySelected = selectedFilters.some(filter => filter.categoryId === categoryId);

    if (!alreadySelected) {
      
        setSelectedFilters([...selectedFilters, { category: category, catid: categoryId }]);
    }

 
};

  const handleClose=(async(item,value)=>{
    setCurrentPage(1)
    removeCategoryFilter(item);
    const updatedCategories=selectedCategories.filter(category => category !== value)
    setSelectedCategories(updatedCategories)
    await fetchCategoryProducts(updatedCategories);


   
  
  
    
  
   

  })

  const startItemIndex = (currentPage - 1) * totalItemsPerPage;
  let endItemIndex = Math.min(
    startItemIndex + totalItemsPerPage,
    productsData?.length
  );

  const filteredProducts = productsData?.filter((product) => {
      setProductLoading(true);
      const productPrice = parseFloat(product.sellingPrice);
      const min = minPrice === "" ? 0 : parseFloat(minPrice);
      const max = maxPrice === "" ? Infinity : parseFloat(maxPrice);
      setProductLoading(false);
      return productPrice >= min && productPrice <= max;
    })
    .sort((a, b) => parseFloat(a.sellingPrice) - parseFloat(b.sellingPrice));
    console.log(filteredProducts,'batfil')


  const paginatedData = appliedFilter
    ? filteredProducts?.slice(startItemIndex, endItemIndex)
    : productsData?.slice(startItemIndex, endItemIndex);

console.log(paginatedData,'osh')

  const totalPages = Math.ceil(
    (appliedFilter ? filteredProducts?.length : productsData?.length) /
    totalItemsPerPage
  );
  const ITEMS_PER_PAGE = 5;
  const startPage = Math.floor((currentPage - 1) / ITEMS_PER_PAGE) * ITEMS_PER_PAGE + 1;
  const endPage = Math.min(startPage + ITEMS_PER_PAGE - 1, totalPages);
  useEffect(()=>{
setCurrentPage(1)
  },[appliedFilter])

  const handleApplyFilter = () => {
    setLoading(true);
    setAppliedFilter(true);
    setCurrentPage(1);
    setLoading(false);
  };

  const handleResetFilter = async () => {
    setLoading(true);
    setAppliedFilter(false);
    setSelectedCategory(null);
    setSelectedValue("");
    setKey(+new Date());
    setDateKey(+new Date() + 2);
    setSelectedDatevalue(null);
    setColorId(null);
    setMinPrice("");
    setMaxPrice("");
    setCurrentPage(1);
    setSelectedCategories([])
    navigate("/shop/products", { replace: true, state: {} });
    let data = await fetchData();
    setInitialProductsData(data);
    setProductsData(data);
    setLoading(false);
  };

  const handleSort = (sortOrder) => {
    setSelectedValue(sortOrder);
    setAppliedFilter(false)
    setSortOrder(sortOrder);
    const sortedProducts = [...productsData]?.sort((a, b) => {
      if (sortOrder === "low-to-high") {
        return a.sellingPrice - b.sellingPrice;
      } else if (sortOrder === "high-to-low") {
        return b.sellingPrice - a.sellingPrice;
      } else {
        return 0;
      }
    });
    setProductsData(sortedProducts);

    setCurrentPage(1)
    setSortedGoods(sortedProducts);
  };

  const handleDate = (value) => {
    setCurrentPage(1)
    if (value == "Newest") {
      const data = [...productsData]?.sort(
        (a, b) => new Date(b.productCreatedAt) - new Date(a.productCreatedAt)
      );
      setProductsData(data);
    } else {
      const data = [...productsData]?.sort(
        (a, b) => new Date(a.productCreatedAt) - new Date(b.productCreatedAt)
      );
    
      setProductsData(data);
    }
  };


  useEffect(() => {
    setSortedGoods(productsData);
  }, []);


  const handleColorChange = async (colorId) => {
 
    const filteredProductsColor = productsData?.filter((product) =>
      product.colors.some((color) => color.colorId == colorId)
    );
    setCurrentPage(1)
    setProductsData(filteredProductsColor);
    //  setProductsData(filteredProductsColor)
    // console.log(isChecked, 'chck')

    // if (!isChecked) {
    //   console.log('enter ticked')
    //   const filteredProductsColor = sortedGoods.filter(product =>
    //     product.colors.some(color => color.colorId == colorId)
    //   );
    //   console.log(filteredProductsColor, 'colorsss')
    //   setSortedGoods(filteredProductsColor)
    //   setProductsData(filteredProductsColor)

    // }
    //   if(isFiltered && isChecked){
    //     console.log('vaadaa')
    //     console.log('sortedOrder',sortedOrder)
    //     fetchData()
    //     handleSort(sortedOrder)
    //   }
    //   if(!isFiltered && isChecked)
    //     {
    //       fetchData()
    //     }
  };
  useEffect(() => {
    setLoading(true);
    const fetchInitialData = async () => {
      const initialData = await fetchData();
      setInitialProductsData(initialData);
    };
    setLoading(false);
    fetchInitialData();
  }, []);

  useEffect(() => {
    handleColorChange(colorId);
  }, [colorId]);

  useEffect(() => {
    const fetchData = async () => {
      if (cid) {
        try {
          setCategoryLoading(true);
          const categoryProducts = await getProductByCategoryId(cid);
        
          setProductsData(categoryProducts?.products);
          setSelectedCategory(cid);
          setCurrentPage(1)
          setSelectedCategories([categoryProducts?.products[0]?.categoryId])
          setAppliedFilter(true)
          setSelectedFilters([{ category:categoryProducts.products[0].categoryDTO.categoryName, catid:categoryProducts?.products[0].categoryId }])
          setLoading(false);
          setCategoryLoading(false);
        } catch (err) {
          if (err?.response?.data?.message) {
            setProductsData([]);

            setLoading(false)
            setCategoryLoading(false)
            setColorLoading(false)
            toast.error("No products found in this category");

          }
          setCategoryLoading(false);
        }
      }
      setCategoryLoading(false);
    };
    fetchData();
  }, [cid]);
  useEffect(() => {
    const fetchData = async () => {
      if (specialPrice) {
        try {
          
        
        
          const products = await GetAllSpecialProducts();
         
         
       
          setProductsData(products.products);
          setCurrentPage(1)
          setAppliedFilter(true);
          setMaxPrice(2000)
          setMinPrice(0)



          setLoading(false);
          setSubCategoryLoading(false);
        } catch (err) {
          if (err?.response?.data?.message) {
            setProductsData([]);

            setLoading(false)
            setCategoryLoading(false)
            setColorLoading(false)
            toast.error("No products found in Special Price");

          }
          setSubCategoryLoading(false);
        }
      }
      setSubCategoryLoading(false);
    };
    fetchData();
  }, [specialPrice]);

  useEffect(() => {
    const fetchData = async () => {
      if (subCategoryId) {
        try {
          setSubCategoryLoading(true);
          setSelectedCategory("");
        
          const products = await getALLProductBySubCategoryId(subCategoryId);
          setSelectedCategories([products[0]?.categoryId])
          addCategoryFilter(products[0]?.categoryDTO?.categoryName,products[0].categoryId)
          setAppliedFilter(true)
          setSelectedFilters([{ category:products[0].categoryDTO.categoryName, catid:products[0].categoryId }])
          setCurrentPage(1)
       
       
          setProductsData(products);



          setLoading(false);
          setSubCategoryLoading(false);
        } catch (err) {
          if (err?.response?.data?.message) {
            setProductsData([]);

            setLoading(false)
            setCategoryLoading(false)
            setColorLoading(false)
            toast.error("No products found in this SubCategory");

          }
          setSubCategoryLoading(false);
        }
      }
      setSubCategoryLoading(false);
    };
    fetchData();
  }, [subCategoryId]);
  const colorMap = {
    "Dark Brown": "#5C4033",
    "Dark Blue": "#00008B",
    "Dark Green": "#006400",
    "White": "#FFFFFF",
    "Tan Brown": "#A52A2A",
    "Maroon": "#800000"
  };
  useEffect(() => {
    const fetchData = async () => {
      if (subSubCategoryId) {
        try {
          setSubCategoryLoading(true);
          setSelectedCategory("");
          const products = await getALLProductBySubSubCategoryId(subSubCategoryId);
          setAppliedFilter(true)
          setSelectedFilters([{ category:products[0].categoryDTO.categoryName, catid:products[0].categoryId }])
  
          addCategoryFilter(products[0]?.categoryDTO?.categoryName,products[0].categoryId)
          setSelectedCategories([products[0]?.categoryId])
          setCurrentPage(1)
          setProductsData(products);



          setLoading(false);
          setSubCategoryLoading(false);
        } catch (err) {
          if (err?.response?.data?.message) {
            setProductsData([]);

            setLoading(false)
            setCategoryLoading(false)
            setColorLoading(false)
            toast.error("No products found in this SubCategory");

          }
          setSubCategoryLoading(false);
        }
      }
      setSubCategoryLoading(false);
    };
    fetchData();
  }, [subSubCategoryId]);
  useEffect(() => {
    const fetchData = async () => {
  //     menId,
  // womenId,
  // unisexId
      if (menId) {
        try {
          setSubCategoryLoading(true);
          setSelectedCategory("");
          const products = await getMenAllProducts(token);
          setSelectedCategories([products[0]?.categoryId])
          addCategoryFilter(products[0]?.categoryDTO?.categoryName,products[0].categoryId)
          setAppliedFilter(true)
          setSelectedFilters([{ category:products[0].categoryDTO.categoryName, catid:products[0].categoryId }])
  
          setCurrentPage(1)
          setProductsData(products.products);



          setLoading(false);
          setSubCategoryLoading(false);
        } catch (err) {
          if (err?.response?.data?.message) {
            setProductsData([]);

            setLoading(false)
            setCategoryLoading(false)
            setColorLoading(false)
            toast.error("No products found in this SubCategory");

          }
          setSubCategoryLoading(false);
        }
      }
      setSubCategoryLoading(false);
    };
    fetchData();
  }, [menId]);
  useEffect(() => {
    const fetchData = async () => {
  //     menId,
  // womenId,
  // unisexId
      if (womenId) {
        try {
          setSubCategoryLoading(true);
          setSelectedCategory("");
          const products = await getWomenAllProducts(token);
          setSelectedCategories([products[0]?.categoryId])
          addCategoryFilter(products[0]?.categoryDTO?.categoryName,products[0].categoryId)
          setAppliedFilter(true)
          setSelectedFilters([{ category:products[0].categoryDTO.categoryName, catid:products[0].categoryId }])
  
          setProductsData(products.products);
          setLoading(false);
          setSubCategoryLoading(false);
        } catch (err) {
          if (err?.response?.data?.message) {
            setProductsData([]);

            setLoading(false)
            setCategoryLoading(false)
            setColorLoading(false)
            toast.error("No products found in this SubCategory");

          }
          setSubCategoryLoading(false);
        }
      }
      setSubCategoryLoading(false);
    };
    fetchData();
  }, [womenId]);
  useEffect(() => {
    const fetchData = async () => {
  //     menId,
  // womenId,
  // unisexId
      if (unisexId) {
        try {
          setSubCategoryLoading(true);
          setSelectedCategory("");
          const products = await getUniSexAllProducts(token);
          setSelectedCategories([products[0]?.categoryId])
          addCategoryFilter(products[0]?.categoryDTO?.categoryName,products[0].categoryId)
          setAppliedFilter(true)
          setSelectedFilters([{ category:products[0].categoryDTO.categoryName, catid:products[0].categoryId }])
 
          setProductsData(products.products);
          setLoading(false);
          setSubCategoryLoading(false);
        } catch (err) {
          if (err?.response?.data?.message) {
            setProductsData([]);

            setLoading(false)
            setCategoryLoading(false)
            setColorLoading(false)
            toast.error("No products found in this SubCategory");

          }
          setSubCategoryLoading(false);
        }
      }
      setSubCategoryLoading(false);
    };
    fetchData();
  }, [unisexId]);
  
  const categoriesToShow = showAll
    ? categoriesData?.slice(0, categoriesData?.length)
    : categoriesData?.slice(0, 4);
  const colorToShow = showAllColor
    ? colorsData?.slice(0, colorsData?.length)
    : colorsData?.slice(0, 4);
     const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  // const toggleClose = ()=>{
  //   setIsDrawerOpen(false);

  // }
  const [isArrowUp, setIsArrowUp] = useState(false);
   const [isArrowPriceUp,setIsArrowPriceUp] = useState(false);
   const [isArrowColorUp, setIsArrowColorUp] = useState(false);
   const handleColorIconClick =() =>{
    setIsArrowColorUp(!isArrowColorUp)
   }
  const handleIconClick = () => {
    setIsArrowUp(!isArrowUp);
  };
  const [isContentVisible, setIsContentVisible] = useState(true);
  const handleIPriceRangeconClick= ()=>{
    setIsArrowPriceUp(!isArrowPriceUp)
  }
  const handlePriceRangeIconClick = () => {
    setIsArrowPriceUp(!isArrowPriceUp);
    setIsContentVisible(!isContentVisible);
  };
  return (
    <>

      {/* <div className="W-[100%] px-[1rem] py-1 p-2">
        {appliedFilter && (
          <>
            <div className="flex flex-col">
              <p className="text-[#020101] text-[20px] hidden sm:flex font-helvetica">Applied Filters</p>


              <div className="flex flex-wrap gap-1 max-w-[500px] relative">
                {selectedFilters?.map((item, index) => (
                  < div key={index} className="flex  r gap-3 border rounded-md p-1" >



                    <p className=""> {item}</p>
                    <div className="w-[1rem] h-[1rem] rounded-full bg-gray-600 flex justify-center items-center ">

                      <IoIosClose className="cursor-pointer text-white" size={30} />
                    </div>
                  </div>


                ))}
              </div>
            </div>
          </>


        )}
      </div> */}

      {categoryLoading ||
        loading ||
        subCategoryLoading ||
        colorLoading ||
        productLoading ? (
        <div className="mx-auto flex items-center mt-96 justify-center">
          <LoadingPage />
        </div>
      ) : (
        <>


       
            <div className="w-[100%] px-[.2rem] xl:px-[1rem] py-2 bg-[#F1F3F6] max-w-[1920px] mx-auto">

              <div className="grid grid-cols-1  xl:grid-cols-6 gap-y-1">

                <div className="col-span-1 gap-3 hidden xl:grid ">

                  <div className=" mb-3  w-[220px] bg-[#FFFFFF] h-fit p-2 xl:ml-1 rounded">
                    <div className="block max-w-[1920px] px-[0rem] xl:p-3 xl:py-3">

                      <div className="w-[25%]">

                        <div className="flex justify-between items-center gap-2">
                          <div>
                            <p className="text-[#020101] text-[20px] font-helvetica hidden xl:flex">Filters</p>
                          </div>
                          
                            <Link
                              className="hidden xl:flex font-helvetica bg-gray-400 items-center justify-center cursor-pointer text-nowrap text-gray mt-1 hover:text-red-700 p-2 m-0 border-none bg-transparent text-[15px]"
                              onClick={handleResetFilter}
                            >
                              Reset All
                            </Link>
                          
                        </div>



                        <div className=" block mt-2">
                          {/* {selectedDate && (
                            <div className="flex items-center mb-1">
                              <span className="font-helvetica text-sm text-[#636363] mr-2">Date:</span>
                              <span className="font-helvetica text-sm text-[#000000]">{selectedDate}</span>
                              <button
                                onClick={() => setSelectedDate('')}
                                className="ml-2 text-red-500 hover:text-red-700"
                              >

                                ×
                              </button>
                            </div>
                          )} */}
                          {selectedSort && (
                            <div className="flex items-center justify-center mb-1">
                              <span className="font-helvetica text-sm text-nowrap text-[#000000]">{selectedSort}</span>
                              <button
                                onClick={() => setSelectedSort('')}
                                className="ml-2  text-red-500 hover:text-red-700"
                              >
                                ×
                              </button>
                            </div>
                          )}


                          {/* {maxPrice > 0 && (
                            <div className="flex items-center mb-1">
                              <span className="font-helvetica text-sm text-[#636363] mr-2">Price:</span>
                              <span className="font-helvetica text-sm text-[#000000]">₹{minPrice}-{maxPrice}</span>
                              <button
                                onClick={() => {
                                  setMinPrice(0);
                                  setMaxPrice(0);
                                }}
                                className="ml-2 text-red-500 hover:text-red-700"
                              >
                                ×
                              </button>
                            </div>
                          )} */}
                          {appliedFilter && (
                            <>
                              <div className="flex flex-col">


                                  {selectedFilters?.map((item, index) => (
                                    < div key={index} className="flex   gap-3  p-1" >



                                      <p className="whitespace-nowrap"> {item.category}</p>
                                      <div className="w-[1rem] h-[1rem] mt-1 rounded-full bg-gray-600 flex justify-center items-center ">

                                        <IoIosClose className="cursor-pointer text-white" size={30} onClick={()=>handleClose(item.category,item.catid)} />
                                      </div>
                                    </div>


                                  ))}
                              </div>
                            </>


                          )}

                          {/* {selectedColor && (
                            <div className="flex items-center mb-2">
                              <span className="font-helvetica text-sm text-[#636363] mr-2">Color:</span>
                              <span className="font-helvetica text-sm text-[#000000]">{selectedColor}</span>
                              <button
                                onClick={() => setSelectedColor('')}
                                className="ml-2 text-red-500 hover:text-red-700"
                              >
                                ×
                              </button>
                            </div>
                          )} */}
                        </div>
                        <hr className="border-t border-gray-700 absolute w-36" />

                        <div className=""></div>
                        <div className="block xl:justify-between items-center xl:mt-2 mb-3">

                          <div className="block items-center gap-1 mt-1 xl:gap-5">
                            {/* <button className="text-[16px] text-[#636363] border border-grey px-2 py-[7px] mt-1 font-helvetica rounded-md">
                              Popularity
                            </button> */}


{/* 
                            <div className=" w-[140px] mt-2 xl:w-[140px]">
                              <button
                                onClick={toggleAccordion}
                                className="block py-1 w-full  text-gray-700 bg-white   flex justify-between items-center"
                              >
                                <span>Date</span>
                                <span className="ml-2 text-gray-500">
                                  {isOpen ? (
                                    <MdKeyboardArrowUp />
                                  ) : (
                                    <MdKeyboardArrowDown />
                                  )}
                                </span>                              </button>

                              {isOpen && (
                                <div className=" top-full left-0 w-full bg-white   mt-1 ">
                                  <div
                                    onClick={() => handleDateSelect('Newest')}
                                    className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100 font-helvetica"
                                  >
                                    Newest
                                  </div>
                                  <div
                                    onClick={() => handleDateSelect('Oldest')}
                                    className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100 font-helvetica"
                                  >
                                    Oldest
                                  </div>
                                </div>
                              )}
                            </div> */}
                          
                            {/* <button className="text-[16px] text-[#636363] border border-grey px-2 py-2 mt-2 font-helvetica rounded-sm">
                              Review
                            </button> */}


                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between items-center font-helvetica">
                        <h1 className="font-helvetica">CATEGORIES</h1>
                      </div>

                      <ul>
                        <li
                          onClick={handleResetFilter}
                          className="flex justify-start items-center gap-3 text-[#636363] text-[16px]"
                        >
                          <Checkbox
                            value="All"
                            checked={selectedCategories.length === 0}
                            id="category-all"
                            onCheckedChange={handleResetFilter}
                            className='mt-3'
                          />
                          <Label htmlFor="category-all" className="font-helvetica mt-3">
                            All
                          </Label>
                        </li>
                        {categoriesToShow?.map((categorie, index) => (
                          <li
                            key={index}
                            className="flex justify-start items-center gap-3 text-[#636363] text-[16px]"
                          >
                            <Checkbox
                              value={categorie.categoryId}
                              id={`category-${index}`}
                              checked={selectedCategories.includes(categorie.categoryId)}
                              onCheckedChange={() => handleCategoryChange(categorie.categoryId)}
                              className='mt-3'
                            />
                            <Label htmlFor={`category-${index}`} className="font-helvetica mt-3">
                              {categorie.categoryName}
                            </Label>
                          </li>
                        ))}

                        <div className="mt-3">
                          <Link
                            onClick={toggleShowAll}
                            className="text-[#636363] font-prompt  cursor-pointer"
                          >
                            {showAll ? "View Less" : "More Categories"}
                          </Link>
                        </div>
                      </ul>
                    </div>
                    <hr className="border-t border-gray-700 absolute w-36 ml-3" />
                    <div className="w-[200px] h-fit p-2 ">
                      <div className="flex justify-between items-center font-helvetica">
                        {/* <h1 className="font-helvetica">COLOR</h1> */}
                      </div>
                      <div className="relative mt-3">
                        <button
                          onClick={toggleColorDropdown}
                          className="w-full text-left flex justify-between items-center bg-white"
                        >
                          <span className="font-helvetica">COLORS
                          
                          </span>
                          <div onClick={handleColorIconClick} className="cursor-pointer">
      {isArrowColorUp ? <MdKeyboardArrowUp size={19} /> : <MdKeyboardArrowDown size={19}/>}
    </div>

                        </button>

                        {isColorDropdownOpen && (
                          <ul className=" mt-2 w-full bg-white  ">
                            <RadioGroup
                              className=""
                              onValueChange={(value) => handleColor(value)}
                            >
                              {colorToShow?.map((item, index) => (
                                <li
                                  key={index}
                                  className="flex justify-start items-center gap-3 text-[#636363] text-[20px]  hover:bg-gray-100 cursor-pointer"
                                  onClick={() => {
                                    handleColor(item.colorId);
                                    toggleColorDropdown();
                                  }}
                                >
                                  <RadioGroupItem
                                    value={item.colorId}
                                    id={`color-${index}`}
                                    color="#FF9F00"
                                    checked={selectedColor === item.colorId}
                                    className="hidden"
                                  />
                                  <Label
                                    htmlFor={`color-${index}`}
                                    className="flex items-center gap-3"
                                  >
                                    <div
                                      style={{ background: colorMap[item?.colorName] || item?.colorName }}
                                      className="w-[21px] h-[21px]  border-grey"
                                    ></div>
                                    <span className="text-[#000000] font-helvetica">
                                      {item.colorName}
                                    </span>
                                  </Label>
                                </li>
                              ))}
                            </RadioGroup>
                            <div
                              className="mt-3  text-start text-[#636363] font-prompt  cursor-pointer"
                              onClick={toggleColorShowAll}
                            >
                              {showAllColor ? "View Less" : "More Colors"}
                            </div>
                          </ul>
                        )}
                      </div>
                    </div>
                    <hr className="border-t border-gray-700 absolute w-36 ml-3" />

                    <div className="relative w-[200px] mt-2">
                              <button
                                onClick={toggleAccordionForPrice}
                                className="block w-full p-2 bg-white  flex justify-between items-center"
                              >
                                <span className="font-helvetica">PRICE</span>
                                <span className="ml-2">
                                
                                <div onClick={handleIconClick} className="cursor-pointer">
      {isArrowUp ? <MdKeyboardArrowUp size={19} /> : <MdKeyboardArrowDown size={19}/>}
    </div>

                                 
                                </span>
                              </button>

                              {isPriceOpen && (
                                <div className=" top-full left-0 w-full bg-white   mt-1 ">
                                  <div
                                    onClick={() => handlePriceSelect('low-to-high')}
                                    className="px-2 py-2 text-gray-700 cursor-pointer hover:bg-gray-100 font-helvetica"
                                  >
                                    Lowest to Highest
                                  </div>
                                  <div
                                    onClick={() => handlePriceSelect('high-to-low')}
                                    className="px-2 py-2 text-gray-700 cursor-pointer hover:bg-gray-100 font-helvetica"
                                  >
                                    Highest to Lowest
                                  </div>
                                </div>
                              )}
                            </div>
                    <hr className="border-t border-gray-700 absolute w-36 ml-3" />

                    <div className="w-[200px] h-fit p-2 flex flex-col mb-3">
                      <div className="flex justify-between items-center font-helvetica">
                        <h1 className="font-helvetica">PRICE RANGE</h1>
                        <div onClick={handlePriceRangeIconClick} className="cursor-pointer">
          {isArrowPriceUp ? (
            <MdKeyboardArrowDown size={19} />
          ) : (
            
            <MdKeyboardArrowUp size={19} />
          )}
        </div>

                      </div>
                      { isContentVisible && 
                      <div className="w-[160px] flex-col mb-4 items-center"> {/* Reduced the width of this container */}
  <Range
    values={[minPrice, maxPrice]}
    step={50}
    min={0}
    max={11000}
    onChange={(values) => {
      setMinPrice(values[0]);
      setMaxPrice(values[1]);
    }}
    renderTrack={({ props, children }) => (
      <div
        onMouseDown={props.onMouseDown}
        onTouchStart={props.onTouchStart}
        className="h-9 flex w-full"
      >
        <div
          ref={props.ref}
          className="h-1.5 w-full rounded bg-black"
          style={{
            background: getTrackBackground({
              values: [minPrice, maxPrice],
              colors: ["#ccc", "#FBD3A4", "#ccc"],
              min: 0,
              max: 11000,
            }),
            alignSelf: "center",
          }}
        >
          {children}
        </div>
      </div>
    )}
    renderThumb={({ props, value, isDragged }) => (
      <div
        {...props}
        className="h-5 w-5 rounded-full bg-[#6C3516] flex justify-center items-center shadow-md relative"
      >
        <div className="h-2 w-[3px] bg-white" />
        <div
          className={`absolute bottom-8 w-10 text-center bg-black text-white text-xs rounded py-1 ${
            isDragged ? "opacity-100" : "opacity-0 group-hover:opacity-100"
          } transition-opacity`}
        >
          ₹{value}
        </div>
      </div>
    )}
  />
  <div className="flex w-[180px] justify-between items-center mt-2">
    <div className="flex items-center">
      <MdCurrencyRupee />
      <input
        type="number"
        value={minPrice}
        onChange={(e) => setMinPrice(parseInt(e.target.value))}
        className="w-[60px] p-1 text-center border border-gray-300 rounded" 
      />
    </div>
    <span className="font-helvetica">to</span>
    <div className="flex items-center">
      <MdCurrencyRupee />
      <input
        type="number"
        value={maxPrice}
        onChange={(e) => setMaxPrice(parseInt(e.target.value))}
        className="w-[60px] p-1 text-center border border-gray-300 rounded" 
      />
    </div>
  </div>
  <Button
    className={`bg-[#FBD3A4] w-full flex justify-center hover:bg-[#FBD3A4] items-center font-helvetica text-black text-[18px] cursor-pointer px-6 mt-2 py-1.5 rounded-xl ${
      (!minPrice ||
        !maxPrice ||
        parseFloat(maxPrice) <= parseFloat(minPrice)) &&
      "bg-gray-300 text-gray-700"
    }`}
    onClick={() => {
      if (
        !minPrice ||
        !maxPrice ||
        parseFloat(maxPrice) <= parseFloat(minPrice)
      ) {
        toast.error("Max Price Should be Greater than Min Price");
      } else {
        handleApplyFilter();
      }
    }}
    disabled={
      !minPrice ||
      !maxPrice ||
      parseFloat(maxPrice) <= parseFloat(minPrice)
    }
  >
    Apply
  </Button>
</div>
}

                     
                    

                    </div>
                  


                  </div>
                </div>

        <div className="bg-gray-100 xl:hidden ">
      {/* Filter Icon */}
      <div className="">
        <button 
          onClick={toggleDrawer} 
          className=" text-black h-[30px] w-[70px] ml-4 bg-[#F9C88F] hover:bg-[#F6B97B] rounded-lg  xl:hidden"
        >
          &#9776; Filter
        </button>
      </div>

      {/* Drawer */}
      <div 
        className={`fixed top-0 left-0 h-full z-50 w-64 bg-white text-black transform transition-transform duration-300 ${
          isDrawerOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="p-2 flex justify-end items-center">
          <button onClick={toggleDrawer} className="text-2xl">
            <IoMdClose />
          </button>
        </div>
        
        {/* Drawer Content */}
        <div className="px-4 overflow-y-auto h-[650px]">
        <div className=" mb-3  w-[220px] bg-[#FFFFFF] h-fit p-2 xl:ml-1 rounded">
                    <div className="block max-w-[1920px] px-[0rem] xl:p-3 xl:py-3">

                      <div className="w-[25%]">
                      
                      <div className="flex justify-between items-center gap-2">
                          <div>
                            <p className="text-[#020101] text-[20px] font-helvetica ">Filters</p>
                          </div>
                          
                            <Link
                              className=" font-helvetica bg-gray-400 items-center justify-center cursor-pointer text-nowrap text-gray mt-1 hover:text-red-700 p-2 m-0 border-none bg-transparent text-[15px]"
                              onClick={handleResetFilter}
                            >
                              Reset All
                            </Link>
                          
                        </div>



                        <div className=" block mt-2">
                          {/* {selectedDate && (
                            <div className="flex items-center mb-1">
                              <span className="font-helvetica text-sm text-[#636363] mr-2">Date:</span>
                              <span className="font-helvetica text-sm text-[#000000]">{selectedDate}</span>
                              <button
                                onClick={() => setSelectedDate('')}
                                className="ml-2 text-red-500 hover:text-red-700"
                              >

                                ×
                              </button>
                            </div>
                          )} */}
                          {selectedSort && (
                            <div className="flex items-center justify-center mb-1">
                              <span className="font-helvetica text-sm text-nowrap text-[#000000]">{selectedSort}</span>
                              <button
                                onClick={() => setSelectedSort('')}
                                className="ml-2  text-red-500 hover:text-red-700"
                              >
                                ×
                              </button>
                            </div>
                          )}


                          {/* {maxPrice > 0 && (
                            <div className="flex items-center mb-1">
                              <span className="font-helvetica text-sm text-[#636363] mr-2">Price:</span>
                              <span className="font-helvetica text-sm text-[#000000]">₹{minPrice}-{maxPrice}</span>
                              <button
                                onClick={() => {
                                  setMinPrice(0);
                                  setMaxPrice(0);
                                }}
                                className="ml-2 text-red-500 hover:text-red-700"
                              >
                                ×
                              </button>
                            </div>
                          )} */}
                          {appliedFilter && (
                            <>
                              <div className="flex flex-col">


                                  {selectedFilters?.map((item, index) => (
                                    < div key={index} className="flex   gap-3  p-1" >



                                      <p className="whitespace-nowrap"> {item.category}</p>
                                      <div className="w-[1rem] h-[1rem] mt-1 rounded-full bg-gray-600 flex justify-center items-center ">

                                        <IoIosClose className="cursor-pointer text-white" size={30} onClick={()=>handleClose(item.category,item.catid)} />
                                      </div>
                                    </div>


                                  ))}
                              </div>
                            </>


                          )}

                          {/* {selectedColor && (
                            <div className="flex items-center mb-2">
                              <span className="font-helvetica text-sm text-[#636363] mr-2">Color:</span>
                              <span className="font-helvetica text-sm text-[#000000]">{selectedColor}</span>
                              <button
                                onClick={() => setSelectedColor('')}
                                className="ml-2 text-red-500 hover:text-red-700"
                              >
                                ×
                              </button>
                            </div>
                          )} */}
                        </div>
                        <hr className="border-t border-gray-700  w-36" />

                        <div className=""></div>
                        <div className="block xl:justify-between items-center xl:mt-2 mb-3">

                          <div className="block items-center gap-1 mt-1 xl:gap-5">
                            {/* <button className="text-[16px] text-[#636363] border border-grey px-2 py-[7px] mt-1 font-helvetica rounded-md">
                              Popularity
                            </button> */}


{/* 
                            <div className=" w-[140px] mt-2 xl:w-[140px]">
                              <button
                                onClick={toggleAccordion}
                                className="block py-1 w-full  text-gray-700 bg-white   flex justify-between items-center"
                              >
                                <span>Date</span>
                                <span className="ml-2 text-gray-500">
                                  {isOpen ? (
                                    <MdKeyboardArrowUp />
                                  ) : (
                                    <MdKeyboardArrowDown />
                                  )}
                                </span>                              </button>

                              {isOpen && (
                                <div className=" top-full left-0 w-full bg-white   mt-1 ">
                                  <div
                                    onClick={() => handleDateSelect('Newest')}
                                    className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100 font-helvetica"
                                  >
                                    Newest
                                  </div>
                                  <div
                                    onClick={() => handleDateSelect('Oldest')}
                                    className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100 font-helvetica"
                                  >
                                    Oldest
                                  </div>
                                </div>
                              )}
                            </div> */}
                          
                            {/* <button className="text-[16px] text-[#636363] border border-grey px-2 py-2 mt-2 font-helvetica rounded-sm">
                              Review
                            </button> */}


                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between items-center font-helvetica">
                        <h1 className="font-helvetica">CATEGORIES</h1>
                      </div>

                      <ul>
                        <li
                          onClick={handleResetFilter}
                          className="flex justify-start items-center gap-3 text-[#636363] text-[16px]"
                        >
                          <Checkbox
                            value="All"
                            checked={selectedCategories.length === 0}
                            id="category-all"
                            onCheckedChange={handleResetFilter}
                            className='mt-3'
                          />
                          <Label htmlFor="category-all" className="font-helvetica mt-3">
                            All
                          </Label>
                        </li>
                        {categoriesToShow?.map((categorie, index) => (
                          <li
                            key={index}
                            className="flex justify-start items-center gap-3 text-[#636363] text-[16px]"
                          >
                            <Checkbox
                              value={categorie.categoryId}
                              id={`category-${index}`}
                              checked={selectedCategories.includes(categorie.categoryId)}
                              onCheckedChange={() => handleCategoryChange(categorie.categoryId)}
                              className='mt-3'
                            />
                            <Label htmlFor={`category-${index}`} className="font-helvetica mt-3">
                              {categorie.categoryName}
                            </Label>
                          </li>
                        ))}

                        <div className="mt-3">
                          <Link
                            onClick={toggleShowAll}
                            className="text-[#636363] font-prompt  cursor-pointer"
                          >
                            {showAll ? "View Less" : "More Categories"}
                          </Link>
                        </div>
                      </ul>
                    </div>
                    <hr className="border-t border-gray-700  w-36" />
                    <div className="w-[200px] h-fit py-1 ">
                      <div className="flex justify-between items-center font-helvetica">
                        {/* <h1 className="font-helvetica">COLOR</h1> */}
                      </div>
                      <div className={`relative mt-3  }`}>
                        <button
                          onClick={toggleColorDropdown}
                          className="w-full text-left flex justify-between items-center bg-white"
                        >
                          <span className="font-helvetica mr-3">COLORS
                          
                          </span>
                          <div onClick={handleColorIconClick} className="cursor-pointer">
      {isArrowColorUp ? <MdKeyboardArrowUp size={19} /> : <MdKeyboardArrowDown size={19}/>}
    </div>

                        </button>

                        {isColorDropdownOpen && (
                          <ul className={` mt-2 w-full bg-white`}>
                            <RadioGroup
                              className=""
                              onValueChange={(value) => handleColor(value)}
                            >
                              {colorToShow?.map((item, index) => (
                                <li
                                  key={index}
                                  className="flex justify-start items-center gap-3 text-[#636363] text-[20px]  hover:bg-gray-100 cursor-pointer"
                                  onClick={() => {
                                    handleColor(item.colorId);
                                    toggleColorDropdown();
                                  }}
                                >
                                  <RadioGroupItem
                                    value={item.colorId}
                                    id={`color-${index}`}
                                    color="#FF9F00"
                                    checked={selectedColor === item.colorId}
                                    className="hidden"
                                  />
                                  <Label
                                    htmlFor={`color-${index}`}
                                    className="flex items-center gap-3"
                                  >
                                    <div
                                      style={{ background: colorMap[item?.colorName] || item?.colorName }}
                                      className="w-[21px] h-[21px]  border-grey"
                                    ></div>
                                    <span className="text-[#000000] font-helvetica">
                                      {item.colorName}
                                    </span>
                                  </Label>
                                </li>
                              ))}
                            </RadioGroup>
                            <div
                              className="mt-3  text-start text-[#636363] font-prompt  cursor-pointer"
                              onClick={toggleColorShowAll}
                            >
                              {showAllColor ? "View Less" : "More Colors"}
                            </div>
                          </ul>
                        )}
                      </div>
                    </div>
                    <hr className="border-t border-gray-700  w-36" />

                    <div className="relative w-[200px] mt-2">
                              <button
                                onClick={toggleAccordionForPrice}
                                className="block w-full py-1 bg-white  flex justify-between items-center"
                              >
                                <span className="font-helvetica">PRICE</span>
                                <span className="ml-2">
                                
                                <div onClick={handleIconClick} className="cursor-pointer">
      {isArrowUp ? <MdKeyboardArrowUp size={19} /> : <MdKeyboardArrowDown size={19}/>}
    </div>

                                 
                                </span>
                              </button>

                              {isPriceOpen && (
                                <div className=" top-full left-0 w-full bg-white   mt-1 ">
                                  <div
                                    onClick={() => handlePriceSelect('low-to-high')}
                                    className="px-2 py-2 text-gray-700 cursor-pointer hover:bg-gray-100 font-helvetica"
                                  >
                                    Lowest to Highest
                                  </div>
                                  <div
                                    onClick={() => handlePriceSelect('high-to-low')}
                                    className="px-2 py-2 text-gray-700 cursor-pointer hover:bg-gray-100 font-helvetica"
                                  >
                                    Highest to Lowest
                                  </div>
                                </div>
                              )}
                            </div>
                    <hr className="border-t border-gray-700  w-36 " />

                    <div className="w-[200px] h-fit py-1 flex flex-col mb-3">
                      <div className="flex justify-between items-center font-helvetica">
                        <h1 className="font-helvetica ">PRICE RANGE</h1>
                        <div onClick={handlePriceRangeIconClick} className="cursor-pointer">
          {isArrowPriceUp ? (
            <MdKeyboardArrowDown size={19} />
          ) : (
            
            <MdKeyboardArrowUp size={19} />
          )}
        </div>

                      </div>
                      {isContentVisible &&
                      <div className="w-[160px] flex-col mb-4 items-center">
                      {/* Range Slider */}
                      <Range
                        values={[minPrice, maxPrice]}
                        step={50}
                        min={0}
                        max={11000}
                        onChange={(values) => {
                          setMinPrice(values[0]);
                          setMaxPrice(values[1]);
                        }}
                        renderTrack={({ props, children }) => (
                          <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            className="h-9 flex w-full"
                          >
                            <div
                              ref={props.ref}
                              className="h-1.5 w-full rounded bg-black"
                              style={{
                                background: getTrackBackground({
                                  values: [minPrice, maxPrice],
                                  colors: ["#ccc", "#FBD3A4", "#ccc"],
                                  min: 0,
                                  max: 11000,
                                }),
                                alignSelf: "center",
                              }}
                            >
                              {children}
                            </div>
                          </div>
                        )}
                        renderThumb={({ props, value, isDragged }) => (
                          <div
                            {...props}
                            className="h-5 w-5 rounded-full bg-[#6C3516] flex justify-center items-center shadow-md "
                            style={{
                              ...props.style,
                              position: 'relative', 
                              top: '50%', // Aligns both thumbs vertically in the center
        transform: 'translateY(-50%)', // Centers the thumbs vertically
        transform: 'translateX(-50%)', // Centers the thumbs vertically
                            }}
                          >
                            <div className="h-2 w-[3px] bg-white" />
                    
                            {/* Tooltip for each thumb */}
                            {isDragged && (
                              <div
                                className="fixed w-10 text-center bg-black text-white text-xs rounded py-1"
                                style={{
                                  position: 'fixed',
                                  left: `${props.style.left}`, // Dynamically position based on thumb's current left value
                                  top: `${props.style.top}`,  // Dynamically position based on thumb's current top value
                                  transform: 'translate(-50%, -100%)', // Center above the thumb
                                }}
                              >
                                ₹{value}
                              </div>
                            )}
                          </div>
                        )}
                      />

  <div className="flex w-[180px] justify-between items-center mt-2">
    <div className="flex items-center">
      <MdCurrencyRupee />
      <input
        type="number"
        value={minPrice}
        onChange={(e) => setMinPrice(parseInt(e.target.value))}
        className="w-[60px] p-1 text-center border border-gray-300 rounded" 
      />
    </div>
    <span className="font-helvetica">to</span>
    <div className="flex items-center">
      <MdCurrencyRupee />
      <input
        type="number"
        value={maxPrice}
        onChange={(e) => setMaxPrice(parseInt(e.target.value))}
        className="w-[60px] p-1 text-center border border-gray-300 rounded" 
      />
    </div>
  </div>
  <Button
    className={`bg-[#FBD3A4] w-full flex justify-center hover:bg-[#FBD3A4] items-center font-helvetica text-black text-[18px] cursor-pointer px-6 mt-2 py-1.5 rounded-xl ${
      (!minPrice ||
        !maxPrice ||
        parseFloat(maxPrice) <= parseFloat(minPrice)) &&
      "bg-gray-300 text-gray-700"
    }`}
    onClick={() => {
      if (
        !minPrice ||
        !maxPrice ||
        parseFloat(maxPrice) <= parseFloat(minPrice)
      ) {
        toast.error("Max Price Should be Greater than Min Price");
      } else {
        handleApplyFilter();
      }
    }}
    disabled={
      !minPrice ||
      !maxPrice ||
      parseFloat(maxPrice) <= parseFloat(minPrice)
    }
  >
    Apply
  </Button>
</div>
}
                     
                    

                    </div>
                  


                  </div>
          {/* Add your categories, color filters, and any other filter options here */}
        </div>
      </div>

      {/* Content Overlay */}
      {isDrawerOpen && (
        <div 
          onClick={toggleDrawer}
          className="fixed inset-0 bg-black opacity-50 md:hidden z-40"
        ></div>
      )}
    </div>

                <div className="w-full xl:w-[99.6%] mt-9 xl:mt-0 bg-[#FFFFFF] col-span-5 xl:ml-4  items-center mb-2">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 -ml-2 xl:-ml-4 xl:grid-cols-3   xl:gap-4 place-items-center">

                    {loading || categoryLoading || subCategoryLoading ? (
                      <div className="flex h-[300px] mt-20 w-[801px] items-center justify-center mb-[60px]">
                        <LoadingPage />
                      </div>
                    ) : paginatedData?.length === 0 ? (
                      <div className="flex items-center mx-auto justify-center h-80">
                        <p className="mx-auto font-helvetica items-center justify-center ml-auto">
                          No products found.
                        </p>
                      </div>
                    ) : (
                      paginatedData?.map((ele, index) => (
                        <div className="col-span-1 xl:gap-6" key={index}>
                          <SingleProduct
                            ele={ele}
                            buttonVisible={true}
                            loading={loading}
                          />
                        </div>
                      ))
                    )}
                  </div>

                  <div className="mt-5 w-[320px] md:w-[500px] overflow-x-auto scrollbar lg:w-full mb-4 flex justify-start lg:justify-center">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      className=""
                    >
                      <PaginationContent>
                        <PaginationPrevious
                          className={`${currentPage === 1 ? "opacity-50 pointer-events-none" : ""} cursor-pointer`}
                          disabled={currentPage === 1}
                          onClick={() => {
                            setCurrentPage(currentPage - 1);
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                        />
    {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(
                          (pageNumber) => (
                            <PaginationItem key={pageNumber}>
                              <PaginationLink
                                isActive={pageNumber === currentPage}
                                onClick={() => {
                                  setCurrentPage(pageNumber);
                                  window.scrollTo({ top: 0, behavior: "smooth" });
                                }}
                                className={`cursor-pointer ${pageNumber === currentPage ? "bg-[#FF9F00] text-white" : "bg-gray-200 shadow-sm text-black"} `}
                              >
                                {pageNumber}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        )}

                        <PaginationNext
                          className={`${currentPage === totalPages ? "opacity-50 pointer-events-none" : ""} cursor-pointer`}
                          disabled={currentPage === totalPages}
                          onClick={() => {
                            setCurrentPage(currentPage + 1);
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                        />
                      </PaginationContent>
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          
        </>
      )}
    </>
  );
};

export default AllFilteredProducts;
