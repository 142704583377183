import React from "react";
import { useParams } from "react-router-dom";
import ProductPage from "../components/Products/Product";
const SingleProductPage = () => {
  return (
    <div>
      <ProductPage />
    </div>
  );
};

export default SingleProductPage;
