import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebook, FaGoogle, FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoading, setToken, setUser } from "../../../redux/slices/authSlice";
import toast from "react-hot-toast";
import { endpoints } from "../../../services/api";
import {
  addItemToCart,
  getCartItemsByUserAndStatusAndProducts,
} from "../../../services/operations/cartItemApi";
import { setCartData, setCartCount } from "../../../redux/slices/cartSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showVerify,setShowVerify]=useState(false)
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [typingField, setTypingField] = useState(null);
  const [emailPlaceHolder, setEmailPlaceHolder] = useState("Enter Email");
  const [passwordPlaceHolder, setPasswordPlaceHolder] = useState("Enter Password");
  const [showPassword, setShowPassword] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showUserNotFound, setShowUserNotFound] = useState(false);

  const addToCart = async (productId, sizeId, colorId, token) => {
    try {
      await addItemToCart(productId, sizeId, colorId, token);
      const response1 = await getCartItemsByUserAndStatusAndProducts(token);
      dispatch(setCartData(response1));
      dispatch(setCartCount(response1?.length));
      navigate('/cart');
      document.cookie = "cart_visited=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    } catch (error) {
      console.log(error);
    }
  };

  const emailRegex = /^[\w-]+(?:\.[\w-]+)*@(?:[a-zA-Z0-9]+\.)+(?:com|in)$/;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!emailId.trim()) {
      newErrors.emailId = "Email should not be empty";
    } else if (!emailRegex.test(emailId)) {
      newErrors.emailId = "Enter a valid Email ID";
    }

    if (!password) {
      newErrors.password = "Password should not be empty";
    }

    return newErrors;
  };
  const deleteCookie = (name) => {
    document.cookie = name + '=; Max-Age=0; path=/; domain=' + window.location.hostname;
  };

  const onSubmit = async (e) => {

    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    // const encodedPass=btoa(password)
    // const encodedEmailId=btoa(emailId)
    // console.log('pass',encodedPass,
    //   'email:',encodedEmailId)

    try {
      const response = await axios.get(endpoints.LOGIN_API, {
        params: {
          emailId: emailId,
          password: password,
        },
      });

      dispatch(setToken(response.data.accessToken));
      dispatch(setUser(response.data));
      localStorage.setItem("token", JSON.stringify(response.data.accessToken));
      localStorage.setItem("userData", JSON.stringify(response.data));
      dispatch(setLoading(false));

      const cookies = document.cookie.split(';');
      let cartVisited = false;

      for (const cookie of cookies) {
        const parts = cookie.trim().split('=');
        if (parts[0] === 'cart_visited') {
          cartVisited = true;
          break;
        }
      }

      if (cartVisited) {
        const token = JSON.parse(localStorage.getItem("token"));
        const selectedSize = localStorage.getItem('selectedSize');
        const selectedProduct = localStorage.getItem('selectedProduct');
        const selectedColor = localStorage.getItem('selectedColor');
     
        await addToCart(selectedProduct, selectedSize, selectedColor, token);
        toast.success("Login Successful");
      } else {
        navigate('/');
        toast.success("Login Successful");
      }
    } catch (error) {
      console.error("Login error:", error?.response?.data?.message);
      if (error?.response?.data?.message == "User not Found") {
        setShowUserNotFound(true);
      }
      if (error?.response?.data?.message == "Bad credentials") {
       setShowUserNotFound(false)
      }
      if (error?.response?.data?.message == "User Not Verified") {
        setShowVerify(true)
        toast.error('User Not Verified.Please Check Email')
       }
      

      // toast.error(error?.response?.data?.message);
      setShowError(true);
      setIsButtonDisabled(true);

      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 500);
    }
  };

  const handleChange = (setValue, value, field) => {
    setValue(value);
    setTypingField(field);
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    setShowError(false);
  };

  const handleBlur = (placeholderSetter, value, initialValue) => {
    if (!value) {
      placeholderSetter(initialValue);
    }
  };

  const handleFocus = (placeholderSetter) => {
    placeholderSetter("");
  };

  return (
    <div className="mx-auto max-w-lg mb-4 mt-1 p-2">
      <h1 className="text-2xl font-helvetica text-center  mb-1 md:text-3xl text-black-700">
        Login
      </h1>

      <div className="bg-white border border-gray-300 rounded-lg p-6 mt-5 mx-auto inline-block w-full">
        <div className="w-full mx-auto mt-1">

          {showError && !showUserNotFound &&  !showVerify &&<div className="text-red-500 text-center">Invalid Credentials</div>}
          {showUserNotFound && <div className="text-red-500 text-center">Email Doesn't Exists</div>}
   

          <form onSubmit={onSubmit}>
            <div className="flex flex-col justify-center pb-3">
              <label className="font-semibold text-base text-gray-700 mb-4" placeholder="Enter Email">
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={emailId}
                onChange={(e) => handleChange(setEmailId, e.target.value, "emailId")}
                onFocus={() => handleFocus(setEmailPlaceHolder)}
                onBlur={() => handleBlur(setEmailPlaceHolder, emailId, "Enter Email")}
                autoFocus
                className={`rounded-[7px] ${errors.emailId ? "border-red-500" : "border-gray-200"
                  } border p-2 placeholder-gray-400 pl-4 cursor-text`}
                placeholder={emailPlaceHolder}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    document.getElementById('passwordInput').focus(); 
                  }
                }}
              />
              {errors.emailId && (
                <p className="text-red-500 text-xs">{errors.emailId}</p>
              )}
            </div>

            <div className="flex relative flex-col justify-center pb-3">
              <label className="font-semibold text-base text-gray-700 mb-2" placeholder="Enter Password">
                Password <span className="text-red-500">*</span>
              </label>
              <div className="w-full relative">
                <input
                  id="passwordInput"
                  type={showPassword ? "text" : "password"}
                  placeholder={passwordPlaceHolder}
                  value={password}
                  max
                  onChange={(e) => handleChange(setPassword, e.target.value, "password")}
                  onFocus={() => handleFocus(setPasswordPlaceHolder)}
                  onBlur={() =>
                    handleBlur(setPasswordPlaceHolder, password, "Enter Password")
                  }
                  className={`rounded-[7px] ${errors.password ? "border-red-500" : "border-gray-200"
                    } w-full border p-2 placeholder-gray-400 pr-10 pl-4 cursor-text`} 
                />
                {errors.password && (
                  <p className="text-red-500 text-xs">{errors.password}</p>
                )}
                <div className="absolute right-3 mt-[.5rem] top-3 transform -translate-y-1/2 cursor-pointer">
                  {showPassword ? (
                    <FaEye
                      onClick={() => setShowPassword(false)}
                      className="text-gray-400"
                    />
                  ) : (

                    <FaEyeSlash
                      onClick={() => setShowPassword(true)}
                      className="text-gray-400"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center pb-2">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-gray-600"
                />
                <span className="ml-2 text-gray-600 font-basicommercial text-lg ">Remember me</span>
              </div>
              <Link
                to="/forgot-password"
                    className="text-blue-500 font-basicommercial  hover:text-blue-900  hover:font-semibold"
              >
                Forgot Password?
              </Link>
            </div>

            <button
              type="submit"
              disabled={isButtonDisabled}
              className="bg-[#F9C88F] hover:bg-[#F6B97B] w-full px-2 py-2 rounded-[15px] mt-2 text-black"
            >
              Login
            </button>
          </form>


        </div>
        <p className="text-center font-basicommercial  flex items-center justify-center mt-2">
          New to LIDKAR?{" "}
          <Link
            to={"/register"}
               className="text-blue-500 ml-2 font-basicommercial  hover:text-blue-900 hover:font-semibold"
          >
            Create Account
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
