import { Skeleton } from "./ui/skeleton"

export function SkeletonCard({ height, width, circle, isAllProducts }) {
  return (
    <div className="flex flex-col space-y-3">
      <Skeleton className={`rounded-xl bg-[#ebc4a3] mb-6 1441px:gap-x-[61px] ${height} ${width}`} />
      {/* <Skeleton className="h-[280px] w-[221p x]  {`rounded-xl`}" /> */}
      <div className="flex justify-between mt-8 items-center ">
        <Skeleton className={`h-10 w-[150px] 1441px:w-[180px] bg-[#ebc4a3]  ${isAllProducts ? "mx-auto" : "" }`} />
        {circle &&
          <Skeleton className="h-10 w-10 rounded-full bg-[#ebc4a3]" />
        }
      </div>
    </div>
  )
}