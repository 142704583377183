import React from "react";

const CancelAndRefund = () => {
  return (
    <div className="max-w-[1920px] mx-auto px-[1rem] xl:px-[5rem]">
      <h1 className="text-2xl font-helvetica mt-5 mb-4">
      Refund and Cancellation
      </h1>
      <p className="text-gray-700 mb-4 font-helvetica text-lg text-justify">Return, Cancellation and Refund Policy of Lidkar
      Return/Replacement Policy:</p>
      <p className="text-gray-700 mb-4 text-[14px] ">
      Can I initiate a request for the Return/Replacement of products?
      </p>
      <li className="text-[14px] mb-2">Yes, you can initiate a request for return / replacement of the products within 15 days from the delivery of the product to you. subject to the below terms & conditions</li>

      <h1 className="text-2xl font-helvetica mt-5 mb-4">
      Terms and Conditions:
      </h1>
      <li className="text-[14px] mb-2">The product in the form of liquid must be returned to Seller (LIDKAR) with proper packing.</li>
      <li className="text-[14px] mb-2">Products being returned should be in good condition otherwise it will not be accepted by the Seller (LIDKAR).</li>
      <li className="text-[14px] mb-2">Product received in bad or damage condition must be reported within 48 hour from delivered time</li>
      <li className="text-[14px] mb-2">In any case, the amount will be refundable to you. the same shall be refunded to you within 7 working days, in accordance with our Refund policy</li>
      
      <h1 className="text-2xl font-helvetica mt-5 mb-4">
      Products that cannot be returned:
      </h1>
      <li className="text-[14px] mb-2">The product that is in unsealed/opened/used condition.</li>
      <li className="text-[14px] mb-2">Products marked as Non-returnable.</li>
      <li className="text-[14px] mb-2">All temperature controlled products (like Insulin, Injection, Temperature Controlled Eye Drops, Chocolates etc.).</li>
      <li className="text-[14px] mb-2">Undergarments as this category is excluded from our general return policy due to hygiene reasons.</li>

      <h1 className="text-2xl font-helvetica mt-5 mb-4">
      Cancellation Policy:
      </h1>
      <li className="text-[14px] mb-2">Is it allowed to request for cancellation?<p>You can cancel any order till the time it is not dispatched from the Seller or Wrong order or to modify in the quantity</p></li>
      <li className="text-[14px] mb-2 text-justify">How can I cancel my order? <p>You will be able to cancel an order till the time it is not dispatched from the seller. In case you are unable to cancel an order from the app or website, you can reject the order at doorstep.
The amount will be refunded to you within 5-7 working days, in accordance with our Refund policy.
No cancellation or Door step rejection allowed for “No returns applicable products” , in case denied at doorstep no refund will be provided for prepaid order.</p></li>
          
<h1 className="text-2xl font-helvetica mt-5 mb-4">
Refund Policy:
      </h1>
      <li className="text-[14px] mb-2">Refund against Cancellation:<p>Customers shall get Refund against Cancellation of order or any failed payment by way of credit to the original mode of payment – Credit/Debit Card or UPI or Net Banking or Third Party Wallet.
      No refund applicable for “No returns applicable products”, if denied at doorstep for prepaid order.</p></li>

      <li className="text-[14px] mb-2 text-justify">Refund against Return:  <p>In case of Cash on Delivery (COD) orders, customers can choose to get their refund on Return(s) through one of the below options. The refund shall be initiated within 5 days from the date of return pickup.
Bank Account
Lidkar wallet
Refund credited to the Bank Account entered by the customer at the time of initiating the return request.
In case of transaction failure due to invalid bank account details, Lidkar reserves the right to refund the amount to the Lidkar Wallet of the customer.
Refund credited to the Bank Account associated with the UPI ID entered by the customer at the time of initiating the return request.
In case the payment is made by Credit/Debit Card, Net Banking or Third Party Wallet, you may get the refund within 5-7 working days. Kindly note that there may be delays in refund as they’re handled by the Bank.</p></li>


<h1 className="text-2xl font-helvetica mt-5 mb-4">

Refund against denied/cancelled order by LIDKAR:
</h1>
<li className="text-[14px] mb-2">In case of online paid order, customers shall get Refund against return by way of credit to the original mode of payment, like Credit/Debit Card, or Net banking or Third Party Wallet.</li>
<li className="text-[14px] mb-2">In case of refund by way of Credit/Debit Card, or Net banking or Third Party Wallet, you may get the credit within 5-7 working days.</li>
<li className="text-[14px] mb-2">While we regret any inconvenience caused by this time frame, as the refund is handled by the Bank.</li>
<li className="text-[14px] mb-2">There may be a delay in refund timing and we have no control over that.
  <p className="text-[14px]">The same shall be operational on all 7 days and timing will be from 9 AM to 5 PM only.</p>
  <p className="text-[14px]">If the status is marked as “REFUND ALLOWED” and is not received by you in 7 working days, kindly contact us at our Consumer Care Cell. We will follow up, until you receive your refund.</p>
</li>

<h1 className="text-2xl font-helvetica mt-5 mb-4">

Warranty and Guarantee:
</h1>
<p className="text-[14px] mb-4 "> Warranty and Guarantee for the electronic appliances or any other products, have specifically mentioned on the product page wherever is applicable</p>
<h1 className="text-2xl font-helvetica mt-5 mb-4">

Delivery & Shipment Tracker:
</h1>
<p className="text-[14px] mb-4 ">Estimated Delivery & Shipping Charges are calculated as per the value of the order and can be viewed in the cart section at the time of checkout. For any further shipping related information, contact us on Lidkar.com</p>
<p className="text-[14px] mb-4 ">Why is the COD option not offered in my location?
Availability of COD depends on the ability of our courier partner servicing your location to accept cash as payment at the time of delivery.</p>
<p className="text-[14px] mb-4 ">Our courier partners have limits on the cash amount payable on delivery depending on the destination and your order value might have exceeded this limit. Please enter your pin code on the product page to check if COD is available in your location”.</p>

    </div>
  );
};

export default CancelAndRefund;
