import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import FAQ from "./pages/FAQ";
import SignupPage from "./pages/SignupPage";
import AddToCartPage from "./pages/AddToCartPage";
import OpenRoute from "./components/core/security/OpenRoute";
import PrivateRoute from "./components/core/security/PrivateRoute";
import TopBar from "./components/Home/TopBar";
import ProductPage from "./pages/ProductPage";
import WhistListEmptyPage from "./pages/WhistListEmptyPage";
import WhistList from "./components/WhistList/WhistList";
import CheckoutPage from "./pages/CheckoutPage";
import Navbar from "./pages/Navbar";
import Footer from "./pages/Footer";
import VerifiyAccount from "./components/auth/Signup/VerifiyAccount";
import SingleProductPage from "./pages/SingleProductPage";
import WhistListPage from "./pages/WhistListPage";
import AllAddressPage from "./pages/AllAddressPage";
import CartPage from "./pages/CartPage";
import Stepper from "./components/Checkout/Stepper";
import ForgotPassword from "./components/auth/ForgotPassword/ForgotPassword";
import UserAccountPage from "./pages/UserAccountPage";
import ForgotPasswordVerify from "./components/auth/ForgotPassword/ForgotPasswordVerify";
import ContactUsPage from "./pages/ContactUsPage";
import OrderDetailsPage from "./pages/OrderDetailsPage";
import AccountDetailsPage from "./pages/AccountDetailsPage";
import OrderRecivedPage from "./pages/OrderRecivedPage";
import OrderTable from "./components/AccountDetails/OrderTable";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditionPage from "./pages/TermsAndConditionPage";
import ReadMorePage from "./components/Home/ReadMorePage";
import ShippingDelivery from "./pages/ShippingDelivery";
import CancelAndRefund from "./pages/CancelAndRefund";
import CustomizedPage from "./pages/CustomizedPage";
import FindStore from "./pages/FindStorePage";
import BrandLidkar from "./pages/BrandLidkar";
function App() {
  return (
    <div className="flex flex-col h-screen">
      {/* <TopBar /> */}
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<ReadMorePage />} />

        <Route
          path="/login"
          element={
            <OpenRoute>
              <LoginPage />
            </OpenRoute>
          }
        />
        <Route
          path="/register"
          element={
            <OpenRoute>
              <SignupPage />
            </OpenRoute>
          }
        />
           <Route
          path="/faq"
          element={
         
              <FAQ />
            
          }
        />

        <Route
          path="/cart"
          element={
            <PrivateRoute>
              <Stepper />
            </PrivateRoute>
          }
        />
        <Route path="shop/products" element={<ProductPage />} />
        <Route
          path="/emptyWhistList"
          element={
            <PrivateRoute>
              <WhistListEmptyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/wishlist"
          element={
            <PrivateRoute>
              <WhistListPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/checkout"
          element={
            <PrivateRoute>
              <CheckoutPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/allAddress"
          element={
            <PrivateRoute>
              <AllAddressPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/verify-email/:verifyToken"
          element={
            <OpenRoute>
              <VerifiyAccount />
            </OpenRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <OpenRoute>
              <ForgotPassword />
            </OpenRoute>
          }
        />
        <Route
          path="/shop/products/:productName"
          element={<SingleProductPage />}
        />
        <Route
          path="/my-account"
          element={
            <PrivateRoute>
              <UserAccountPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/forgot-password/:verifyToken"
          element={
            <OpenRoute>
              <ForgotPasswordVerify />
            </OpenRoute>
          }
        />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/findStore" element={<FindStore/>} />
        <Route path="/lidkarBrand" element={<BrandLidkar/>} />
        <Route path="/customized" element={<CustomizedPage />} />
        <Route
          path="/accountDetails/:id"
          element={
            <PrivateRoute>
              <AccountDetailsPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/orderConfirmed/my-order/:orderId"
          element={
            <PrivateRoute>
              <OrderRecivedPage />
            </PrivateRoute>
          }
        />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsAndCondition" element={<TermsAndConditionPage />} />
        <Route path="/shippingAndDelivery" element={<ShippingDelivery />} />
        <Route path="/cancelAndRefund" element={<CancelAndRefund />} />
            
         
        <Route
          path="/order-details"
          element={
            <PrivateRoute>
              <OrderDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-orders"
          element={
            <PrivateRoute>
              <div className="mt-10 flex items-center justify-center mx-auto w-full h-[400px]">
                <OrderTable />
              </div>
            </PrivateRoute>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
