import React from 'react'

const FindStorePage = () => {
    const googleMapsUrl =
    "https://www.google.com/maps/place/Sunplus+Software+Technologies+Pvt.+Ltd/@12.9642888,77.5939862,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae142aac1c6dc1:0xee30d20a0d56a3a9!8m2!3d12.9642836!4d77.5965611!16s%2Fg%2F11dymc5205?entry=ttu&g_ep=EgoyMDI0MDgyNy4wIKXMDSoASAFQAw%3D%3D";
  return (
    <div className='max-w-[1920px] mx-auto px-[1rem] lg:px-[5rem] py-2'>   
        <h1 className='text-center font-helvetica font-bold text-base mt-3 mb-[41px]'>FIND A NEAREST STORE</h1>
             
         <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-2 md:p-4">
         <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'>No.11, Shopping Complex, Jayanagar</p>
        <p className='text-black  font-helvetica'>Bangalore - 560011</p>
        <p className='text-black font-helvetica'>Mo: 9480886291</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'>Shop No.209, 1.T.Park Complex Hosur NH4,</p>
        <p className='text-black  font-helvetica'> Hubli - 580029</p>
        <p className='text-black font-helvetica'>Mo: 9480886275</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'>No.77-34, 42"4 Cross, No.3 New Fish Market,
         Subramanyeshwara Mansion 3™ Block, Rajajinagar,</p>
        <p className='text-black  font-helvetica'>Bangalore - 560010</p>
        <p className='text-black font-helvetica'>Mo: 7411223926</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'> BBMP Complex, Behind Upparpet Police CTI Building, Sayyajirao Road,
        Station, Subhashnagar,</p>
        <p className='text-black  font-helvetica'>Bangalore - 560009</p>
        <p className='text-black font-helvetica'>Mo:9341821512</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'> BBMP Complex, Behind Upparpet Police CTI Building, Sayyajirao Road,
        Station, Subhashnagar,</p>
        <p className='text-black  font-helvetica'>Bangalore - 560009</p>
        <p className='text-black font-helvetica'>Mo:9341821512</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'>No.24, ‘B’ Block, Buda Complex, 
        Ground Floor, </p>
        <p className='text-black  font-helvetica'>Bellary - 583101</p>
        <p className='text-black font-helvetica'>Mo:9480886274</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'>Goaves Shopping Complex,
        Basaveshwara Circle</p>
        <p className='text-black  font-helvetica'>Belgaum - 590001</p>
        <p className='text-black font-helvetica'>Mo:9480886266</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'> ATI Complex,Mallandur Road, NMC. Circle
        </p>
        <p className='text-black  font-helvetica'>Chikamagalur - 577101</p>
        <p className='text-black font-helvetica'>Mo:9980238737</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'>No.8, Tennis Court Complex,
        Govt. Boys PU Colleage Ground, 2nd Main, Akkamahadevi Road 
        </p>
        <p className='text-black  font-helvetica'>Davangere -57702</p>
        <p className='text-black font-helvetica'>Mo:9480886275</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'>Janatha Bazaar Complex Super Market,
        </p>
        <p className='text-black  font-helvetica'> Gulbarga - 582101</p>
        <p className='text-black font-helvetica'>Mo:9886548880</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'>No.3 New Fish Market,
        </p>
        <p className='text-black  font-helvetica'> Mangalore ~ 575001
        </p>
        <p className='text-black font-helvetica'>Mo:9886548880</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'>CTI Building, Sayyajirao Road,
        </p>
        <p className='text-black  font-helvetica'>  Mysore - 570001
        </p>
        <p className='text-black font-helvetica'>Mo:9480886258</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'>No.2, MahilaSamaja Complex,  Jail Road, 
        </p>
        <p className='text-black  font-helvetica'> Raichur - 584101
        </p>
        <p className='text-black font-helvetica'>Mo:9480886261</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'>Shop No.22, Red Cross Bhavan,  Ashoka Road,         </p>
        <p className='text-black  font-helvetica'> Tumkur - 572101
        </p>
        <p className='text-black font-helvetica'>Mo:9480886288</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'>Shop No.22, Red Cross Bhavan,  Ashoka Road,         </p>
        <p className='text-black  font-helvetica'> Tumkur - 572101
        </p>
        <p className='text-black font-helvetica'>Mo:9480886288</p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
      <div className=" p-4 text-start shadow-md border border-gray-300">
        <h1 className='text-black font-bold font-helvetica text-base'>Lidkar Leather Emporium</h1>
        <p className='text-black  font-helvetica'> No.8 Shree Shakthi B.D.Road, Near  Travellers Bungalow,
        </p>
        <p className='text-black  font-helvetica'>  Chitradurga -577501
        </p>
        <p className='text-black font-helvetica'>Mo:9480886272 </p>
        <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer" 
        className="text-blue-500 underline"
      >
        Find a Store
      </a>
      </div>
    </div>
    </div>
  )
}

export default FindStorePage