import React, { useEffect, useState } from "react";
import { FaRegDotCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  createAddress,
  getAllAddress,
  removeAddress,
  getAddressById,
} from "../../services/operations/addressApi";
import AddressModel from "../models/AddressModel";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import toast from "react-hot-toast";
import { Button } from "../ui/button";
import PaymentSummary from "./PaymentSummary";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { Label } from "@radix-ui/react-label";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Card } from "../ui/card";
import LoadingPage from "../../pages/LoadingPage";
import { BASE_URL } from "../../services/api";

const PaymentStep = ({
  calculateTotalPrice,
  addressId,
  cartDetails,
  gstPrice,
  sellingPrice,
}) => {

  // console.log()
  const { token, user } = useSelector((state) => state.auth);
  const [selectedValue, setSelectedValue] = useState("comfortable");
  const [loading, setLoading] = useState(false);
  const [allAddressData, setAllAddressData] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [orderId, setOrderId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
 
  const { firstName, lastName, emailId, mobileNumber } = user;

  const [loadingPayment, setLoadingPayment] = useState(false);
  const navigate = useNavigate();

  const userId = user?.userId;

  const handleChange = (event) => {
    setSelectedValue(event?.target?.value);
   
  };

  // const totalPriceWithoutGst = Math.round(calculateTotalPrice() * 100);
  // const gstPrice = Math.round((calculateTotalPrice() * 0.18) * 100);

  // const finalPrice = Math.round(totalPriceWithoutGst + gstPrice);



  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAllAddress(userId, token);
        const slectedAddress = await getAddressById(addressId, token);
       
        setAllAddressData(response);
        setSelectedAddress([slectedAddress]);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [userId, token]);

  useEffect(() => {
    // Load the Razorpay script dynamically (optional, but recommended)
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      // Clean up the script on component unmount (optional)
      document.body.removeChild(script);
    };
  }, []);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  // const handlePayment = async () => {
  //   setLoadingPayment(true);
  //   try {
  //     if (paymentType === "Pay Now") {
  //       const res = await loadScript(
  //         "https://checkout.razorpay.com/v1/checkout.js"
  //       );
  //       if (!res) {
  //         toast.error("RazorPay SDK failed to load");
  //         return;
  //       }

  //       const requestData = {
  //         userDetailsDTO: {
  //           userName: `${firstName} ${lastName}`,
  //           emailId,
  //           mobileNumber,
  //         },
  //         addressId,
  //         paymentDetailsDTO: {
  //           paymentType: "Pay Now",
  //           totalPrice: Math.round(calculateTotalPrice()),
  //         },
  //         totalPrice: Math.round(calculateTotalPrice()),
  //       };

  //       const headers = {
  //         Authorization: `Bearer ${token}`,
  //       };

  //       const responsee = await axios.post(
  //         `${BASE_URL}/api/checkout/createCheckout`,
  //         requestData,
  //         { headers }
  //       );

  //       if (!responsee.data) {
  //         throw new Error("Error While Creating Checkout");
  //       }

  //       const newOrderId = responsee?.data?.orderId;
  //       setOrderId(newOrderId);
  //       console.log(newOrderId);

  //       if (responsee.data.paymentType === "Pay Now") {
  //         const responseMakePayment = await axios.post(
  //           `${BASE_URL}/api/payment/makePayment`,
  //           { orderId: newOrderId },
  //           { headers }
  //         );

  //         const amount = Math.round(calculateTotalPrice() * 100);

  //         const options = {
  //           description: "Purchase Description",
  //           image:
  //             "https://res.cloudinary.com/dlt18old9/image/upload/v1701173512/test11.png",
  //           currency: "INR",
  //           key: "rzp_test_E2v6chvoceMDTP",
  //           amount,
  //           name: "Lidkar",
  //           prefill: {
  //             email: emailId,
  //             contact: mobileNumber,
  //             name: `${firstName} ${lastName}`,
  //           },
  //           theme: { color: "#56233A" },
  //           handler: async (response) => {
  //             console.log("response in confirm payment", response);
  //             try {
  //               setLoading(true);
  //               setLoadingPayment(true);
  //               await axios.post(
  //                 `${BASE_URL}/api/payment/confirmPayment`,
  //                 {
  //                   orderId: newOrderId,
  //                   paymentId: response.razorpay_payment_id,
  //                 },
  //                 { headers }
  //               );
  //               setLoadingPayment(false);
  //               navigate(`/orderConfirmed/my-order/${newOrderId}`);
  //               setLoading(false);
  //               setLoadingPayment(false);
  //             } catch (error) {
  //               console.error("Error confirming payment:", error);
  //               setLoading(false);
  //               setLoadingPayment(false);
  //             }
  //           },
  //         };

  //         const paymentObject = new window.Razorpay(options);
  //         paymentObject.open();
  //         paymentObject.on("payment.failed", function (response) {
  //           toast.error("Oops, payment failed");
  //           console.log(response.error);
  //         });
  //       }
  //       setLoadingPayment(false);
  //     } else if (paymentType === "Pay Later") {
  //       setLoadingPayment(true);
  //       const requestData = {
  //         userDetailsDTO: {
  //           userName: `${firstName} ${lastName}`,
  //           emailId,
  //           mobileNumber,
  //         },
  //         addressId,
  //         paymentDetailsDTO: {
  //           paymentType: "Pay Later",
  //           totalPrice: Math.round(calculateTotalPrice()*100),
  //         },
  //         totalPrice: Math.round(calculateTotalPrice()*100),
  //       };

  //       const headers = {
  //         Authorization: `Bearer ${token}`,
  //       };

  //       const response = await axios.post(
  //         `${BASE_URL}/api/checkout/createCheckout`,
  //         requestData,
  //         { headers }
  //       );

  //       console.log("API response:", response.data);
  //       navigate(`/orderConfirmed/my-order/${response.data.orderId}`);
  //     } else {
  //       console.error("No payment type selected");
  //     }
  //   } catch (error) {
  //     console.error("Error while processing payment:", error);
  //   }
  //   setLoadingPayment(false);
  // };

  const handlePayment = async () => {
    setLoadingPayment(true);
    try {
      if (paymentType === "Pay Now") {
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
          toast.error("RazorPay SDK failed to load");
          return;
        }
  
        const totalPriceInPaise = Math.round(calculateTotalPrice() * 100);
  
        const requestData = {
          userDetailsDTO: {
            userName: `${firstName} ${lastName}`,
            emailId,
            mobileNumber,
          },
          addressId,
          paymentDetailsDTO: {
            paymentType: "Pay Now",
            totalPrice: totalPriceInPaise / 100,  // Send amount in rupees for backend processing
          },
          totalPrice: totalPriceInPaise / 100,  // Send amount in rupees for backend processing
        };
  
        const headers = {
          Authorization: `Bearer ${token}`,
        };
  
        const responsee = await axios.post(
          `${BASE_URL}/api/checkout/createCheckout`,
          requestData,
          { headers }
        );
  
        if (!responsee.data) {
          throw new Error("Error While Creating Checkout");
        }
  
        const newOrderId = responsee?.data?.orderId;
        setOrderId(newOrderId);
     
  
        if (responsee.data.paymentType === "Pay Now") {
          const responseMakePayment = await axios.post(
            `${BASE_URL}/api/payment/makePayment`,
            { orderId: newOrderId },
            { headers }
          );
  
          const options = {
            description: "Purchase Description",
            image:
              "https://res.cloudinary.com/dlt18old9/image/upload/v1701173512/test11.png",
            currency: "INR",
            key: "rzp_test_E2v6chvoceMDTP",
            amount: totalPriceInPaise,  // Send amount in paise
            name: "Lidkar",
            prefill: {
              email: emailId,
              contact: mobileNumber,
              name: `${firstName} ${lastName}`,
            },
            theme: { color: "#56233A" },
            handler: async (response) => {
           
              try {
                setLoading(true);
                setLoadingPayment(true);
                await axios.post(
                  `${BASE_URL}/api/payment/confirmPayment`,
                  {
                    orderId: newOrderId,
                    paymentId: response.razorpay_payment_id,
                  },
                  { headers }
                );
                setLoadingPayment(false);
                navigate(`/orderConfirmed/my-order/${newOrderId}`);
                setLoading(false);
                setLoadingPayment(false);
              } catch (error) {
                console.error("Error confirming payment:", error);
                setLoading(false);
                setLoadingPayment(false);
              }
            },
          };
  
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
          paymentObject.on("payment.failed", function (response) {
            toast.error("Oops, payment failed");
            console.log(response.error);
          });
        }
        setLoadingPayment(false);
      } else if (paymentType === "Pay Later") {
        setLoadingPayment(true);
        const totalPriceInPaise = Math.round(calculateTotalPrice() * 100);
  
        const requestData = {
          userDetailsDTO: {
            userName: `${firstName} ${lastName}`,
            emailId,
            mobileNumber,
          },
          addressId,
          paymentDetailsDTO: {
            paymentType: "Pay Later",
            totalPrice: totalPriceInPaise / 100,  // Send amount in rupees for backend processing
          },
          totalPrice: totalPriceInPaise / 100,  // Send amount in rupees for backend processing
        };
  
        const headers = {
          Authorization: `Bearer ${token}`,
        };
  
        const response = await axios.post(
          `${BASE_URL}/api/checkout/createCheckout`,
          requestData,
          { headers }
        );
  
    
        navigate(`/orderConfirmed/my-order/${response.data.orderId}`);
      } else {
        console.error("No payment type selected");
      }
    } catch (error) {
      console.error("Error while processing payment:", error);
    }
    setLoadingPayment(false);
  };
  

  return loadingPayment || loading ? (
    <div className="flex w-full h-full items-center mt-40 1441px:mt-64 justify-center">
      <LoadingPage />
    </div>
  ) : (
    <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-1 lg:grid-cols-1 gap-16 max-w-[1920px] mx-auto lg:px-[0rem] lg:py-3">
      <div className="flex flex-col flex-1">
        <div className="flex justify-between items-center lg:gap-7 flex-col mb-4">
          <p className="font-orator text-[#303030] text-[19px] self-center lg:self-start lg:ml-3">
            SELECTED DELIVERY ADDRESS
          </p>

          {selectedAddress?.map((address, index) => (
            <div className="flex flex-1 gap-9 justify-between flex-wrap w-full p-3  lg:p-1 lg:p-0">
              <Card className="border border-[#D9D9D9] sm:w-full lg:w-[550px] w-[400px] h-[150px]">
                <div className="flex justify-start items-center gap-6  p-3">
                  <div className="font-helvetica font-bold text-[16px] ml-8">
                    {user?.firstName + " " + user?.lastName}
                  </div>
                  {/* <div className="text-[#03A685] border border-[#03A685] w-[75px] h-[25px] px-1 py-3 rounded-full flex justify-center items-center font-prompt">
                    Home
                  </div> */}
                </div>

                {address?.street ||
                address?.landmark ||
                address?.city ||
                address?.state ? (
                  <p key={index} className="text-[#6C6C6C] font-basicommercial text-[15px] px-10">
                    {`${address?.street}, ${address?.landmark}, ${address?.city}, ${address?.state}`}
                  </p>
                ) : null}

                <p className="font-helvetica text-[#6C6C6C] px-10 mt-5">
                  Mobile
                  <span className="font-bold font-basicommercial ml-2">{user?.mobileNumber}</span>
                </p>
              </Card>
            </div>
          ))}

          {/* {cartDetails.map((cartDetail) => (
            <Card className="grid grid-cols-2 md:grid-cols-2 gap-4">
              <div className="h-[150px] w-[150px]">
                <img src={cartDetail.productDTO.productImage[0].imagePath} />
              </div>
              <div>{cartDetail.productDTO.productName}</div>
            </Card>
          ))} */}
        </div>
      </div>
      <div className="lg:mr-3  flex flex-1 flex-col  bg-white">
        <p className="text-[#03A685] text-[21px] font-orator font-bold whitespace-nowrap px-9 mt-3">
          Payment Summary
        </p>
        <PaymentSummary
          gstPrice={gstPrice}
          sellingPrice={sellingPrice}
          calculateTotalPrice={calculateTotalPrice}
        />
        <RadioGroup defaultValue={selectedValue} onValueChange={handleChange}>
          <div className="flex justify-start px-9 mt-5 items-center gap-5">
            <div className="flex items-center space-x-2">
              <RadioGroupItem
                onClick={() => setPaymentType("Pay Now")}
                value="Pay Now"
                id="r1"
              />
              <Label
                htmlFor="r1"
                className="text-[#0D0D0D] font-helvetica text-[19px]"
              >
                Pay Now
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem
                onClick={() => setPaymentType("Pay Later")}
                value="Pay Later"
                id="r2"
                className="text-[19px] font-bold"
              />
              <Label
                htmlFor="r2"
                className="text-[#0D0D0D] font-helvetica text-[19px]"
              >
                Cash On Delivery
              </Label>
            </div>
          </div>
        </RadioGroup>

        <div className="mt-3 px-9">
          {paymentType === "Pay Later" ? (
            <div>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <button className="font-helvetica mb-3 bg-[#F9C88F]  hover:bg-[#F6B97B] w-fit px-[9px] py-[9px]  text-[18px] text-black rounded ">
                    Proceed To Order
                  </button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      Are you absolutely sure?
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      Order will be placed and can be viewed in my orders
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      className="bg-orange-950 text-white"
                      onClick={handlePayment}
                    >
                      Place Order
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          ) : (
            <div className="mb-3 px-1">
              <Button
                disabled={!paymentType}
                onClick={handlePayment}
                className="font-helvetica bg-[#F9C88F] h-[39px] w-[50px] flex justify-center items-center w-fit  text-[18px] text-black rounded  hover:bg-[#F6B97B]"
              >
                Proceed To Payment
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentStep;
