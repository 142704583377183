import React from "react";
import { GiShoppingCart } from "react-icons/gi";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";

const CartEmptyPage = () => {
  return (
    <div className="w-1920 px-[5rem] py-2">
      <div className="flex flex-col items-center justify-center my-8 1441px:mt-48">
        <div className="flex flex-col space-y-3">
          <h1 className="font-bold text-2xl font-basicommercial  text-center">Hey It Feels Empty Here</h1>
          <span className="text-center font-basicommercial text-lg ">There is nothing in your Cart, Let’s add some item</span>
          <div className="flex items-center justify-center">
            <GiShoppingCart size={100} />
          </div>

          <div className="flex gap-5 sm:gap-10">
            <Button variant="outline" className="border-pink-400">
              <Link to={"/wishlist"}>Add Items from Wishlist</Link>
            </Button>
            <Button variant="outline" className="border-pink-400">
              <Link to={"/"}>Continue Shopping</Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartEmptyPage;
