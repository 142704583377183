import { FaStar } from "react-icons/fa6";
import { MdOutlineStarBorderPurple500 } from "react-icons/md";

const StarRating = ({ rating }) => {
    const stars = [];
    
    // Create an array of 5 stars
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(<FaStar color="#E61F7F" />); // Full star
      } else {
        stars.push(<MdOutlineStarBorderPurple500 color="#E61F7F"/>); // Empty star
      }
    }
    return (

        <div className="flex">
        {stars}
      </div>
  
    )

}
export default  StarRating