import React from 'react'
import Loading from '../components/Loader/Loading'

const LoadingPage = () => {
  return (
    <div className="w-full -mt-72 flex justify-center items-center">
      <Loading></Loading>
    </div>
  )
}

export default LoadingPage