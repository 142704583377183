import React from 'react';
import AllAddress from '../components/AllAddress/AllAddress';
const AllAddressPage = () => {
  return (
    <div>
      <AllAddress/>
    </div>
  );
}

export default AllAddressPage;

