import { getAllCategories, getAllSubCategories } from "../../../services/operations/cartegoryApi";
import React, { useEffect, useState } from "react";
import { Skeleton } from "../../ui/skeleton";
import { useNavigate } from "react-router-dom";

const MobileCategories = () => {
  const [loading, setLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAllSubCategories();
        const filteredItems = response.filter(item => item?.subCategoryImage?.length > 0);
        
        setCategoriesData(filteredItems);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        (prevIndex + 1) % categoriesData.length
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [categoriesData]);

  const handleCategories = (id) => {
    navigate(`/shop/products?id=${id}`);
  };

  const skeletonCount = 5;

  return (
    <div className="flex lg:hidden px-[0rem] w-full  h-[270px] overflow-hidden relative">
      <ul
        className="flex items-center justify-center transition-transform duration-500 ease-in-out"
        style={{
          transform: `translateX(-${(currentIndex * 25) / categoriesData.length}%)`,
        }}
      >
        {loading || categoriesData?.length < 1
          ? Array.from({ length: skeletonCount }).map((_, index) => (
              <Skeleton
                key={index}
                className="w-[230px] h-[263px] 1441px:w-[248px] 1441px:h-[261px] 1441px:mt-3 rounded-sm bg-[#ebc4a3]"
              />
            ))
          : [...categoriesData, ...categoriesData, ...categoriesData, ...categoriesData]?.map((data, index) => (
              <li
                key={index}
                onClick={() => {
                  handleCategories(data?.categoryId);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className="w-[160px] h-[200px] flex flex-col items-center mx-3"
              >
                <img
                  src={data?.subCategoryImage[0]?.imagePath}
                  className="hover:scale-110 w-full h-full object-cover transition duration-300 cursor-pointer"
                  alt={data?.categoryName}
                />
                <p className="font-prompt font-medium text-base mt-2 whitespace-nowrap">
                  {data?.subCategoryName}
                </p>
              </li>
            ))}
      </ul>
    </div>
  );
};

export default MobileCategories;
