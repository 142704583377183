import React from "react";
import wishlist from "../../images/wishlist.png";
import { Link } from "react-router-dom";
const WhistListEmpty = () => {
  return (
    <div className="w-1920 sm:px-[5rem] py-2 flex justify-center 1441px:mt-[8rem] items-center flex-col mt-12 gap-6">
      <p className="font-prompt text-[21px] font-semi-bold text-[black] ">
        YOUR WISHLIST IS EMPTY
      </p>
      <p className="text-[#514C4C] font-prompt text-[15px] w-[375px] sm:w-[470px] text-center">
        Add items that you like to your wishlist. Review them anytime and easily
        move them to the bag.
      </p>
      <img src={wishlist} alt="wishlist" className="w-[100px] h-[100px]" />
      <Link
        to="/"
        className="text-[19px] border border-[#E61F7F] font-bold px-7 py-2 1441px:mb-[2rem]"
      >
        CONTINUE SHOPPING
      </Link>
    </div>
  );
};

export default WhistListEmpty;
