import React, { useEffect, useState } from "react";
import WhistList from "../components/WhistList/WhistList";
import { useDispatch, useSelector } from "react-redux";
import { getAllWishlistsByUser } from "../services/operations/wishlistApi";
import { setWishlisData, setWishlistCount } from "../redux/slices/wishlistSlice";

const WhistListPage = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [wishlistLoading, setWishlistLoading] = useState(false);
  const [wishlistData1, setWishlistData1] = useState([]);
  const {wishlistData} = useSelector((state) => state.wishlist);

  console.log(wishlistData);

  const getAllWishlists = async () => {
    try {
      setWishlistLoading(true);
      const response = await getAllWishlistsByUser(token);
      console.log("Wishlist", response);
      setWishlistData1(response);
      dispatch(setWishlisData(response));
      dispatch(setWishlistCount(response?.length));
      setWishlistLoading(false);
    } catch (error) {
      console.log(error);
      setWishlistLoading(false);
    }
  };
  useEffect(() => {
    getAllWishlists();
  }, []);

  return (
    <WhistList wishlistData={wishlistData}  wishlistLoading={wishlistLoading} />
  );
};

export default WhistListPage;
