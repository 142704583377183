import React from 'react'
import OrderDetails from '../components/AccountDetails/OrderDetails'

const OrderDetailsPage = () => {
  return (
    <div>
      <OrderDetails/>
    </div>
  )
}

export default OrderDetailsPage
