import React from 'react'
import { FaArrowUp } from 'react-icons/fa'

const UpArrow = ({onClick}) => {
  return (
    <>
        <div onClick={onClick} className="arrow-container cursor-pointer  flex items-center justify-center bg-[#6C3516] rounded-full w-[50px] h-[50px] px-2 py-2">
        <FaArrowUp className="up-arrow text-white text-xl" />
      </div>
    </>

  )
}

export default UpArrow
