import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "../slices/authSlice"
import categorySlice from "../slices/categorySlice";
import wishlistSlice from "../slices/wishlistSlice";
import cartSlice from "../slices/cartSlice";

const rootReducer = combineReducers({
  auth : authSlice,
  category: categorySlice,
  wishlist : wishlistSlice,
  cart : cartSlice
})

export default rootReducer;