import React from "react";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({ data }) => {
  const navigate = useNavigate();

  const handleCategories = (id) => {
    navigate('/shop/products', { state: { subCategoryId: id } });
  };
  return (
    <div
      onClick={() => {
        handleCategories(data?.subCategoryId);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
      className="w-246 h-261 1441px:h-[261px] 1441px:w-[246px] flex flex-1 flex-col items-center cursor-pointer overflow-hidden "
    >
      <div className="overflow-hidden">
  <img
    src={data?.subCategoryImage[0]?.imagePath}
    className="1441px:h-[261px] min-h-[261px] max-h-[261px] 1441px:mt-4 1441px:w-[246px] object-fit transition-transform  custum-transition-duration ease-out transform hover:scale-110"
  />
</div>

     
      <p className="font-orator font-medium mt-3 1441px:mt-2text-lg ">
       {data?.subCategoryName}
      </p>
    </div>
  );
};

export default CategoryCard;
