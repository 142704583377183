import { useState } from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { FaEye, FaEyeSlash } from "react-icons/fa"
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { resetPassword } from "../../services/operations/authApi";

const ChangePasswordModel = () => {
  const [open, setOpen] = useState(false);
  const [currentPassPlaceHolder, setCurrentPassPlaceHolder] = useState('Current Password')
  const [newPassPlaceHolder, setNewPassPlaceHolder] = useState('New Password')
  const [confirmPassPlaceHolder, setConfirmPassPlaceHolder] = useState('Confirm New Password')
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [typingField, setTypingField] = useState(null)
  const [confirmPass, setConfirmPass] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { token, user } = useSelector((state) => state.auth);

  const validatePassword = (password) => {
    const errors = {};
    if (!password.currentPass) {
      errors.currentPass = "Current password is required";
      return errors
    }
    if (!password.newPass) {
      errors.newPass = "New password is required";
      return errors
    } else if (password.newPass.length < 8) {
      errors.newPass = "Password must be at least 8 characters";
      return errors
    } else if (!/[a-zA-Z]/.test(password.newPass)) {
      errors.newPass = "Password must contain alphabets";
      return errors
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password.newPass)) {
      errors.newPass = "Password must contain a special character";
      return errors
    }
    if (!password.confirmPass) {
      errors.confirmPass = "Please confirm your new password";
      return errors
    } else if (password.newPass !== password.confirmPass) {
      errors.confirmPass = "Passwords must match";
      return errors
    }
    return errors;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validatePassword({
      currentPass,
      newPass,
      confirmPass,
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {

    
      resetPassword(currentPass, newPass, token).then((response) => {
      
        setOpen(false)
        setCurrentPass('')
        setConfirmPass('')
        setNewPass('')


      })


    }
  };
  const handleBlur = (placeholderSetter, value, initialValue) => {
    if (!value) {
      placeholderSetter(initialValue);
    }
  };
  const handleFocus = (placeholderSetter) => {
    placeholderSetter('');
  };
  const handleChange = (setValue, value, field) => {



    setValue(value);
    setTypingField(field)
    setErrors(({ [setValue]: '' }));

  };
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>

        <p className="text-blue-500 text-sm mt-0  cursor-pointer hover:text-blue-900 active:bg-blue-800 ">
          Change Password
        </p>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-[#000000] font-Nunito bold text-[24px]">
            Change Password
          </DialogTitle>
          <form onSubmit={onSubmit} className="space-y-4">
            <div className="flex flex-col ">
              <label className="font-semibold text-base text-gray-700 mb-4">
                Enter Current Password <span className="text-red-500">*</span>
              </label>
              <div className="w-full relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={currentPass}
                  maxLength='20'
                  onChange={(e) => handleChange(setCurrentPass, e.target.value, 'currentPass')}
                  placeholder={currentPassPlaceHolder}
                  className={`rounded-[7px] ${errors.newPass ? "border-red-500" : "border-gray-200"}  border p-2 placeholder-gray-400 pl-4 w-full`}
                  onFocus={() => handleFocus(setCurrentPassPlaceHolder)}
                  onBlur={() => handleBlur(setCurrentPassPlaceHolder, currentPass, 'Current Password')}

                />
                {errors.currentPass && (
                  <small className="text-red-500">{errors.currentPass}</small>
                )}
                {typingField === "currentPass" && (
                  <small className="text-gray-500 flex justify-end">({currentPass.length}/50)</small>
                )}
                <div className="absolute right-[10px] top-[22px]  transform -translate-y-1/2 cursor-pointer">
                  {showPassword ? (
                    <FaEyeSlash
                      onClick={() => setShowPassword(false)}
                      className="text-gray-400 "
                    />
                  ) : (
                    <FaEye
                      onClick={() => setShowPassword(true)}
                      className="text-gray-400"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <label className="font-semibold text-base text-gray-700 mb-4">
                New Password <span className="text-red-500">*</span>
              </label>
              <div className="w-full relative">
                <input
                  type={showNewPassword ? "text" : "password"}
                  value={newPass}
                  maxLength='20'
                  onChange={(e) => handleChange(setNewPass, e.target.value, 'newPass')}
                  placeholder={newPassPlaceHolder}
                  onFocus={() => handleFocus(setNewPassPlaceHolder)}
                  onBlur={() => handleBlur(setNewPassPlaceHolder, newPass, 'New Password')}

                  className={`rounded-[7px] ${errors.newPass ? "border-red-500" : "border-gray-200"}  border p-2 placeholder-gray-400 pl-4 w-full`}
                />
                {errors.newPass && (
                  <small className="text-red-500">{errors.newPass}</small>
                )}
                {typingField === "newPass" && (
                  <small className="text-gray-500 flex justify-end">({newPass.length}/50)</small>
                )}
                <div className="absolute right-[10px] top-[22px]  transform -translate-y-1/2 cursor-pointer">
                  {showNewPassword ? (
                    <FaEyeSlash
                      onClick={() => setShowNewPassword(false)}
                      className="text-gray-400 "
                    />
                  ) : (
                    <FaEye
                      onClick={() => setShowNewPassword(true)}
                      className="text-gray-400"
                    />
                  )}
                </div>

              </div>
            </div>
            <div className="flex flex-col">
              <label className="font-semibold text-base text-gray-700 mb-4">
                Confirm New Password <span className="text-red-500">*</span>
              </label>
              <div className="w-full relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  maxLength='20'
                  value={confirmPass}
                  onChange={(e) => handleChange(setConfirmPass, e.target.value, 'confirmPass')}
                  placeholder={confirmPassPlaceHolder}
                  onFocus={() => handleFocus(setConfirmPassPlaceHolder)}
                  onBlur={() => handleBlur(setConfirmPassPlaceHolder, confirmPass, 'Confirm New Password')}

                  className={`rounded-[7px] ${errors.confirmPass ? "border-red-500" : "border-gray-200"}  border p-2 placeholder-gray-400 pl-4 w-full`}
                />
                {errors.confirmPass && (
                  <small className="text-red-500">{errors.confirmPass}</small>
                )}
                {typingField === "confirmPass" && (
                  <small className="text-gray-500 flex justify-end">({confirmPass.length}/50)</small>
                )}
                <div className="absolute right-[10px] top-[22px]  transform -translate-y-1/2 cursor-pointer">
                  {showConfirmPassword ? (
                    <FaEyeSlash
                      onClick={() => setShowConfirmPassword(false)}
                      className="text-gray-400 "
                    />
                  ) : (
                    <FaEye
                      onClick={() => setShowConfirmPassword(true)}
                      className="text-gray-400"
                    />
                  )}
                </div>

              </div>
            </div>
            <button
              type="submit"
              className="bg-[#56243B] py-3 text-white rounded-lg px-7"
            >
              Submit
            </button>
          </form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordModel;
