import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartLoading: false,
  cartData:[],
  cartCount: null
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    setCartLoading : (state,action) => {
      state.cartLoading = action.payload;
    },
    setCartData: (state, action) => {
      state.cartData = action.payload;
    },
    setCartCount : (state,action) => {
      state.cartCount = action.payload;
    }
  },
});

export const { setCartData, setCartCount } = cartSlice.actions;
export default cartSlice.reducer;
