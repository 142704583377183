import toast from "react-hot-toast";
import { wishlistEndPoints } from "../api";
import { apiConnector } from "../apiConnector";

export const addProductToWishlist = async (token, productId) => {
  let result;
  try {
    const response = await apiConnector(
      "POST",
      wishlistEndPoints.ADD_ITEM_TO_WISHLIST,
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      { productId: productId }
    );
    if (response.data) {
      toast.success("Item added To Wishlist");
    }
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong while adding to wishlist");
  }
};

export const getAllWishlistsByUser = async (token) => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      wishlistEndPoints.GET_ALL_WISHLIST_ITEMS,
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    console.log(result);
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const removeSingleWishList = async (token, wishlistId) => {
  let result;
  try {
    const response = await apiConnector(
      "DELETE",
      wishlistEndPoints.REMOVE_SINGLE_WISHLIST,
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      { wishlistId: wishlistId }
    );
    if (response.data) {
      toast.success("Item deleted from Wishlist");
    }
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.message);
  }
};