import React from 'react';
import Checkout from '../components/Checkout/Checkout';
const CheckoutPage = () => {
  return (
    <div>
       <Checkout/>
    </div>
  );
}

export default CheckoutPage;
