import toast from "react-hot-toast";
import { colorEndPoints } from "../api";
import { apiConnector } from "../apiConnector";

export const getAllColors = async (token) => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      colorEndPoints.GET_ALL_COLORS,
      {},
    )
    console.log(response);
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};