import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/card";
import { Button } from "../../ui/button";
import axios from "axios";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { BASE_URL } from "../../../services/api";

const ForgotPasswordVerify = () => {
  const { verifyToken } = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [showPasswordNotMatch, setShowPasswordNotMatch] = useState(false);
  const navigate = useNavigate();

  const verifyForgotPassword = async (token, password) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${BASE_URL}/verifyForgotPassword`,
        { token, password },
        { headers: { "Content-Type": "application/json" } }
      );
     
      if (response.data) {
        toast.success(response.data);
        setTimeout(() => {
          navigate("/login");
        }, 500);
      }
       else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = () => {
    if (password !== confirmPassword) {
      setShowPasswordNotMatch(true)
      // toast.error("Passwords do not match");
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000)
      return;
    }
    verifyForgotPassword(verifyToken, password);
  };

  return (
    <div className="flex items-center justify-center mt-4">
      <Card className="w-[35%] h-full">
        <CardHeader className="flex flex-col items-center justify-center">
          <CardTitle className="text-lg font-prompt">Reset Password</CardTitle>
          <CardDescription className="font-Barlow">
            Forgot your password? No worries! 
          </CardDescription>
        </CardHeader>
        
        <CardContent className="flex flex-col gap-2">
          <div className="relative">
            <input
              className="w-full p-3 border rounded-md focus:ring-0 outline-none"
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Enter New Password"
              value={password}
            />
            <div className="absolute right-3 top-6 transform -translate-y-1/2 cursor-pointer">
              {showPassword ? (
                <FaEye
                onClick={() => setShowPassword(false)}
                className="text-gray-400"
              />
                
              ) : (
                <FaEyeSlash
                  onClick={() => setShowPassword(true)}
                  className="text-gray-400 "
                />
              )}
            </div>

          </div>
          <div className="relative">
            <input
              className="w-full p-3 border rounded-md focus:ring-0 focus:ring-orange-950
             outline-none mt-2"
              onChange={(e) => setConfirmPassword(e.target.value)}
              type={showPasswordConfirm ? "text" : "password"}
              name="confirmPassword"
              placeholder="Confirm New Password"
              value={confirmPassword}
            />
            <div className="absolute right-3 top-7 transform -translate-y-1/2 cursor-pointer">
              {showPasswordConfirm ? (
                <FaEye
                onClick={() => setShowPasswordConfirm(false)}
                className="text-gray-400 mt-2"
              />
                
              ) : (
                <FaEyeSlash
                  onClick={() => setShowPasswordConfirm(true)}
                  className="text-gray-400 mt-2"
                />
              )}
            </div>

          </div>
        </CardContent>
        {showPasswordNotMatch && (
          <div className="text-red-500 text-sm text-start pl-6 -mt-6">
            Passwords do not match
          </div>
        )}
        <CardFooter className="flex justify-center mt-1 right-0">
          <Button
            onClick={handleChangePassword}
            disabled={
              isButtonDisabled || !password || !confirmPassword || password.length <= 7 || loading
            }
            className="w-fit px-3 bg-[#2c1211]"
          >
            {loading ? "Changing Password..." : "Change Password"}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ForgotPasswordVerify;
