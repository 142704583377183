import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
}

const categorySlice = createSlice({
  name : "category",
  initialState: initialState,
  reducers: {
    setLoading : (state,action) =>{
      state.loading = action.payload
    }
  }
})


export const {setLoading} = categorySlice.actions;
export default categorySlice.reducer;