import { setLoading } from "../../redux/slices/categorySlice";
import { apiConnector } from "../apiConnector";
import { categoryEndPoints } from "../api";
import { featuredProductEndPoints } from "../api";

export const getAllCategories = async () => {
    let result = [];
    try {
        const response = await apiConnector("GET", categoryEndPoints.GET_ALL_CATEGORIES);
        console.log("response", response);
        result = response
            ?.data
    } catch (error) {
        console.log(error);
    }
    return result;
}
export const getAllSubCategories = async () => {
    let result = [];
    try {
        const response = await apiConnector("GET", categoryEndPoints.GET_ALL_SUBCATEGORIES);
        console.log("response", response);
        result = response
            ?.data
    } catch (error) {
        console.log(error);
    }
    return result;
}
export const getCategoryById = async (categoryId, token) => {
    let result = [];
    try {
        const response = await apiConnector("GET", categoryEndPoints.GET_CATEGORY_BY_ID
            ,
            {},
            { Authorization: `Bearer ${token}` },
            { categoryId }
        );
        console.log("response", response);
        result = response
            ?.data
        return result;
    } catch (error) {
        console.log(error);
    }

}
export const getByCategories = async () => {
    let result = [];
    try {
        const response = await apiConnector("GET", categoryEndPoints.GET_BY_CATEGORY);
        console.log("response", response);
        result = response
            ?.data
    } catch (error) {
        console.log(error);
    }
    return result;
}
export const getFeaturedProduct = async () => {
    let result = [];
    try {
        const response = await apiConnector("GET", featuredProductEndPoints.GET_FEATURED_PRODUCT);
        console.log("varuva", response);
        result = response
            ?.data
            return result;
    } catch (error) {
        console.log(error);
    }
   
}
