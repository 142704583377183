import { apiConnector } from "../apiConnector";
import { orderEndPoints } from "../api";

export const getAllOrders = async (token) => {
  let result = [];
  console.log(token, "tkn");
  try {
    const response = await apiConnector(
      "GET",
      orderEndPoints.GET_ALL_ORDERS,
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      {}
    );
    console.log(response, "res");
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getOrderDetailsById = async (orderId, token) => {
    let result = null;
    console.log(token, "tkn");
    try {
      const response = await apiConnector(
        "GET",
        orderEndPoints.GET_ORDER_DETAILS_BY_ID,
        {},
        {
          Authorization: `Bearer ${token}`,
        },
        {orderId}
      );
      result = response.data;
      console.log(response, "res");
      return result;
    } catch (error) {
      console.log(error);
    }
  };
