import { Link } from "react-router-dom";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";

const SigninModel = () => {
  return (
    <Dialog>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-[#000000] font-Nunito bold text-[24px]">
            Sign in/Sign up
          </DialogTitle>
          <DialogDescription className="text-[#EC0606] text-[20px] font-outFit">
            Sign in/Signup Required to Add Items to Your Cart
          </DialogDescription>
          <DialogDescription className="text-[#050505]  text-[15px] font-outFit">
            No worries! It only takes a minute to sign in and unlock a world of
            convenient shopping.
          </DialogDescription>
          <DialogDescription>
            <Link
              to={"/login"}
              className="bg-[#56243B] py-3 text-white rounded-lg px-7"
            >
              Go to Sign in
            </Link>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default SigninModel;
