export const footerMenuItems = [
    { footerName: "Home", footerLInks: "/" },
    { footerName: "About Us", footerLInks: "/about-us" },
    { footerName: "Contact Us", footerLInks: "/contact-us" },
    { footerName: "Brand Lidkar", footerLInks: "/lidkarBrand" },
  ];
  export const footerQuickLinks = [
    { name: "Footwear", id: 1 },
    { name: "Bags", id: 2 },
    { name: "Jackets", id: 7 },
    { name: "Accessories", id: 3 }
  ];
export const  FooterCustomerInfo = ["Privacy Policy", "Terms and Conditions", "Cancellation & Exchange", "Shipping and Delivery"]
export const UsefulLinks = ["Frequently Asked Questions", "Find Our Nearest Store", "Track Your Order", "For queries, customercare@lidkar.com"]
 


