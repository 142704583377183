import React from "react";
import { HiCheckBadge } from "react-icons/hi2";
import shoe from "../../images/shoes.png";
import { MdCurrencyRupee } from "react-icons/md";
import LoadingPage from "../../pages/LoadingPage";

const OrderRecived = ({ orderLoading, orderDetails }) => {

  console.log(orderDetails);
  
  if (orderLoading) {
    return (
      <div className="w-full h-full mx-auto my-auto justify-center items-center">
        <LoadingPage />
      </div>
    );
  }

  console.log("orderDetails", orderDetails);
  return orderLoading ? (
    <div className="w-full h-full mx-auto my-auto justify-center items-center">
      <LoadingPage />
    </div>
  ) : (
    <div className="w-1920 px-[5rem] py-3 mt-9 ">
      <div className="flex gap-3">
        <div className="flex items-center mb-1">
          <HiCheckBadge fontSize={41} color="green" />
        </div>
        <div className="flex flex-col">
          <h1 className="text-[#000000] text-[30px] font-orator font-bold ">
            Thanks for your order!
          </h1>
          <p className="text-[#000000] text-base font-helvetica font-semi-bold">
            Order No : #{orderDetails?.orderDTO.orderNumber}
          </p>
        </div>
      </div>
      <div className="flex w-full">
        <div className="flex flex-col max-h-[500px] overflow-y-scroll border border-slate-200 shadow-sm w-[600px] p-6 rounded-md gap-4 mt-5">
          {orderDetails?.productDTO.map((product, index) => (
            <div className="border border-[#D9D9D9] w-[550px] h-[250px] rounded-lg">
              <div className="flex justify-between items-center w-full ">
                <div className="flex w-[35%] ">
                  <div className="w-[12rem]h-[12rem] bg-[#EFEFEF] m-3">
                    <img
                      src={product?.productImage[0].imagePath}
                      alt="shoes"
                      className="w-[9rem] h-[7rem]"
                    />
                  </div>
                </div>
                <div className="flex w-[75%] flex-col">
                  <p className="text-[#636363] text-[12px] font-helvetica ">{product?.categoryDTO.categoryName}</p>
                  <p className="text-[#303030] font-orator font-semi-bold whitespace-nowrap">
                    {product?.productName.length > 30
                      ? product?.productName.substring(0, 30) + "..."
                      : product?.productName}
                  </p>
                  <div className="flex gap-5 mt-5 ">
                    <div className="border border-[#D9D9D9] w-[6.4rem] h-[2.5rem]  rounded-sm flex gap-5 justify-center items-center p-2">
                      <p className="text-[#514F4F] font-helvetica">Size</p>
                      <span className="w-[2rem] h-[2rem] border border-[#D9D9D9] font-basicommercial rounded-full flex justify-center items-center">
                        <p>{product?.selectedSize}</p>
                      </span>
                    </div>
                    <div className="border border-[#D9D9D9] w-[6.4rem] h-[2.5rem] rounded-sm  flex gap-5 justify-center items-center p-2">
                      <p className="text-[#514F4F] font-helvetica">Color</p>
                      <span
                        style={{ background: product.selectedColor }}
                        className="w-[1.5rem] h-[1.5rem] border border-[#D9D9D9] rounded-full flex justify-center items-center"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <hr color="grey" />
              <div className="flex justify-between items-center p-3">
                <div className="flex flex-col">
                  <p className="text-[#303030] text-base   font-bold">
                    Estimated delivery date
                  </p>
                  <p className="font-Nunito text-[14px] mt-3">
                    {new Date(
                      Date.now() + 5 * 24 * 60 * 60 * 1000
                    ).toLocaleDateString()}
                  </p>
                </div>
                <div className="flex">
                  <button className="px-4 py-2 bg-[#E8396C] text-[white] rounded-full">
                    Track your order
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-[360px] h-[180px] mt-10 mx-auto bg-[#F6F6F6] rounded-lg">
          <p className="text-[#000000] font-prompt text-base font-bold px-4 py-2">
            Payment Summary
          </p>
          {orderDetails?.productDTO.length > 0 && (
            <>
              <div className="flex justify-between items-center px-4 py-1">
                <p className="text-base text-[#090909] font-Nunito">
                  Total Price
                </p>
                <p className="text-base font-bold text-[#000000] font-prompt flex items-center">
                  <MdCurrencyRupee />{" "}
                  {orderDetails.productDTO.reduce(
                    (acc, curr) => acc + Math.round(curr.sellingPrice),
                    0
                  )}
                </p>
              </div>
              <div className="flex justify-between items-center px-4 py-1">
                <p className="text-base text-[#090909] font-Nunito">
                  Total GST Amount
                </p>
                <p className="text-base font-bold text-[#000000] font-prompt flex items-center">
                  <MdCurrencyRupee />{" "}
                  {orderDetails.productDTO.reduce(
                    (acc, curr) => acc + (Math.round(curr.gstAmount)),
                    0
                  )}
                </p>
              </div>

              <hr className="mt-3" />
              <div className="flex mt-3 justify-between items-center px-4 py-1">
                <p className="text-base text-[#090909] font-Nunito">
                  Total Amount
                </p>
                <p className="text-base font-bold text-[#000000] font-prompt flex items-center">
                  <MdCurrencyRupee />{" "}
                  {Math.round(orderDetails?.orderDTO?.totalPrice)}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderRecived;
