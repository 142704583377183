import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const FrequentlyAskedQuestions = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const questions = [
    {
      question: "Why should I consider leather over synthetic materials?",
      answer:
        "Unlike synthetic materials, leather is a natural organic material derived from renewable source and is far more aesthetically beautiful, long lasting, and versatile. The appearance of good leather doesn’t deteriorate as it ages, instead it acquires character- thanks to the fine patina that develops over time. Although the initial investment of quality leather may be more than synthetic materials, leather is actually your best value in the long run as it outlasts other materials 4 to 1."
    },
    {
      question: "How do I differentiate between genuine leather and synthetic one?",
      answer:
       "There are three basic tests that you can perform to confirm the material is indeed leather.Smell Test: First, most leather has a characteristic odor of fat-liquor (oils used to lubricate the leather). If the material doesn’t have this “regular smell of leather”, chances are it is not genuine leather. Please note that many of the newer “washable” or water-resistant leather do not have the same fat-liquors and often do not have much of a characteristic leather odor.Look Test: Looking at the back side of the leather will also reveal its genuine character. The back of the leather should look like suede. If you see threads, that is a bad sign. If you see the ends of the collagen fibers, like you would on a suede pair of shoes, that is good.If possible, you can also inspect the cut edge – the leather has a very distinctive layered appearance from the edge. The grain and finish are very tight and compact. Often you can see the hair follicles in this layer if you cut just right. Then the inner layer is coarser and a random tangle of heavier fibers. If you can see any crisscross pattern or symmetry, that is a sure sign of synthetic materials.Fire Test: When exposed to mild temperatures (say match flame), the genuine leather will not catch fire, while other synthetic materials are inflammable and will burn quickly. Leather sort of melts into a lump that will harden like glass when cool. Will also shatter like glass if crushed after it has been burned."

    },
    {
      question: "What to look in a handmade leather bag?",
      answer:
       "The type of leather used to make that bag is the first characteristic to look for in a handcrafted leather bag. Next, there comes craftsmanship, quality, durability & stitching quality."
    },
    {
      question: "Do leather handbags get soft over time?",
      answer:
       "Yes, leather handbags become softer with use. The natural oils in the human skin help to moisturise the leather, which makes it softer & supple."
    },
    {
      question: "Are leather bags fine for all weather?",
      answer:
        "Yes, they are. Just make sure to care properly with each use."
    },
    {
      question: "How to care leather handbags during the harsh summer & wet rainy season?",
      answer:
      "Leather handbags are perfect for summer use. Brief exposure to the heat of up to 100°C will not harm genuine leather - no issues. During the rainy season, genuine leather handbags can withstand water to a certain extent. So it would be better to cover the bag during the rain."
    },
    {
      question: "Do leather handbags cracks?",
      answer:
    "A faux leather handbag will crack with rough usage. If your bag is made from low-quality genuine cowhide leather, it will peel off too. But high-quality cowhide leather handbags will not have this issue."
    },
    {
      question: "How to maintain leather handbags in humid weather?",
      answer:
      "In humid weather, the users need to keep them in an airy location & wipe the bags frequently with a dry cloth."
    },
    {
      question: "Are leather handbags machine washable?",
      answer:
    "Yes, they are. But reading the care label in bags can help to decide better."
    },
    
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="max-w-[1920px] mx-auto p-4 px-[1rem] xl:px-[5rem]">
      <h1 className="text-2xl text-center mb-4 font-helvetica">Frequently Asked Questions</h1>
      <div className="space-y-4">
        {questions.map((faq, index) => (
          <div key={index} className="border border-gray-300 rounded-lg p-4 shadow-sm">
            <button
              className="w-full text-left flex justify-between items-center"
              onClick={() => handleToggle(index)}
            >
              <span className="text-lg text-[14px] font-helvetica">{faq.question}</span>
              <span className="ml-2">
                {activeIndex === index ? (
                  <AiOutlineEyeInvisible className="w-5 h-5" />
                ) : (
                  <AiOutlineEye className="w-5 h-5" />
                )}
              </span>
            </button>
            {activeIndex === index && (
              <p className="mt-2 text-gray-600 text-[14px] font-helvetica">{faq.answer}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
