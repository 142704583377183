import { apiConnector } from "../apiConnector";
import { productsEndPoints } from "../api";
import { featuredProductEndPoints } from "../api";
import { specialPriceEndPoints } from "../api";

export const getAllProducts = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_ALL_PRODUCTS
    );
    result = response?.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};
export const GET_ALL_FEATUREDPRODUCT = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      featuredProductEndPoints.GET_FEATURED_PRODUCT
    );
    result = response?.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};
export const GetAllSpecialProducts = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      specialPriceEndPoints.GET_SPECIAL_PRICE
    );
    result = response?.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};
export const getMenAllProducts = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GETMENSPRODUCTS
    );
    result = response?.data;
    console.log("resiltData", result)
  } catch (error) {
    console.log(error);
  }
  return result;
};
export const getWomenAllProducts = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GETWOMENSPRODUCTS
    );
    result = response?.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};
export const getUniSexAllProducts = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GETUNISEXPRODUCTS
    );
    result = response?.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};

export const getProductByProductName = async (productName) => {
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_PRODUCT_BY_PRODUCTNAME,
      {},
      {},
      {
        productName: productName,
      }
    );
    let data = response.data;
    result = data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getProductByProductId = async (productId) => {
  let result;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_PRODUCT_BY_PRODUCTID,
      {},
      {},
      {
        productId: productId,
      }
    );
    console.log(result);
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getProductByCategoryId = async (categoryId) => {
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_PRODUCT_BY_CATEGORYID,
      {},
      {},
      {
        categoryId: categoryId,
      }
    );
    let data = response?.data;
    console.log(data, "datavi");
    result = data;
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getALLProductByCategoryId = async (categoryId) => {
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_ALLPRODUCTS_BY_CATEGORY,
      {},
      {},
      {
        categoryId,
      }
    );
    let data = response?.data;
    console.log(data, "data");
    result = data;
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getALLProductBySubCategoryId = async (subCategoryId) => {
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_ALLPRODUCTBY_SUBCATEGORIES,
      {},
      {},
      {
        subCategoryId,
      }
    );
    let data = response?.data;
    console.log(data, "data");
    result = data;
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getALLProductBySubSubCategoryId = async (subSubCategoryId) => {
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_PRODUCT_BY_SUB_SUB_CATEGORYID,
      {},
      {},
      {
        subSubCategoryId,
      }
    );
    let data = response?.data;
    console.log(data, "datavikas");
    result = data;
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};


export const getProductByMultipleCategoryId = async (categoryIds,token) => {
  let result = null;
  console.log(categoryIds,'pokko')
  if (!Array.isArray(categoryIds)) {
    console.log('categoryIds must be an array');
  }
  const categoryIdsParam = Array.isArray(categoryIds) ? categoryIds.join(',') : '';

  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_PRODUCT_BY_MULTIPLE_CATEGORYID,
      {},
      {  Authorization: `Bearer ${token}`,},
      {
        categoryIds:categoryIdsParam
      }
    );
    let data = response?.data;
    console.log(data, "data");
    result = data;
    return result;
  } catch (error) {
    console.log(error,'vasu');
    throw error;
  }
};

export const searchByProductName = async (productName) => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.SEARCH_BY_PRODUCT_NAME,
      {},
      {},
      {
        name: productName,
      }
    );
    console.log("search", response.data);
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getProductBySubCategoryId = async (subCategoryId) => {
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_PRODUCT_BY_SUB_CATEGORYID,
      {},
      {},
      {
        subCategoryId,
      }
    );
    let data = response?.data;
    console.log(data, "data");
    result = data;
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
}