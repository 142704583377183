import React from 'react';
import Stepper from './Stepper';
const Checkout = () => {
  return (
    <div>
       <Stepper/>
    </div>
  );
}

export default Checkout;
