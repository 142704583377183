import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  wishlistData: [],
  wishlistCount: null
}

const wishlistSlice = createSlice({
  name : "wishlist",
  initialState: initialState,
  reducers: {
    setLoading : (state,action) =>{
      state.loading = action.payload
    },
    setWishlisData: (state,action) => {
      state.wishlistData = action.payload
    },
    setWishlistCount : (state,action) => {
      state.wishlistCount = action.payload
    }
  }
})

export const {setLoading, setWishlisData, setWishlistCount} = wishlistSlice.actions;
export default wishlistSlice.reducer;