import React from 'react'
import Signup from '../components/auth/Signup/Signup'
import Navbar from './Navbar'
import TopBar from '../components/Home/TopBar'

const SignupPage = () => {
  return (
    <div>
      <Signup />
    </div>
  )
}

export default SignupPage