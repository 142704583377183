import React from 'react'

const BrandLidkar = () => {
  return (
    <div className='max-w-[1920px] xl:px-[5rem] px-[1rem] py-2 mx-auto'>
         <h1 className='text-2xl font-helvetica mb-4 mt-3'>Brand LIDKAR</h1>
             <p className='mb-4   text-[14px] text-justify'>
Lidkar. A confluence of ancient, traditional, rich artistry, authentic leather and sui generis designs.
</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar is an iconic, world-renowned, premium Indian leather brand, founded in 1976.
Lidkar’s legendary, folklore-inspired, signature weave patterns inherent to few communities of local Kannadiga artisans with the know-how and skill-set that been passed down the generations, exquisite leather quality that defies the sands of time, finesse, master craftsmanship coupled with innovative, stylish and timeless designs has enamoured fashionistas and bewitched leather aficionados worldwide. 

</p>
             <p className='mb-4   text-[14px] text-justify'>
             Each product is a work of nuanced contemporary art, meticulously handcrafted by skilled artisans that breathe life into it and bequeath a story to fit your individuality for an everlasting companionship. Every product is in vogue with the fashion trends raging across the globe and a step ahead of the rest with a little more à la mode.
</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar is where India’s finest quality leather blends with eons of knowledge, craftsmanship & mastery and truly is a place where luxury meets comfort.

Lidkar today enjoys the confidence and patronage of connoisseur of leather ranging from erstwhile royalty, top celebrities & divas of Indian cinema, A-listers from sports, politics, art & culture, fashion world and other prominent personas from all walks of life.

Lidkar has a long history of producing exquisite, high-quality and wide-array of leather products, including footwear, handbags, wallets, and belts. 

</p>

<h1 className='text-2xl font-helvetica mb-4'>Fashion Mission : 
</h1>
<h1 className='font-helvetica font-bold text-base mt-3'>A Perfect Amalgamation of ancient and future
 
</h1>
<p className='mb-4   text-[14px] text-justify'>
Lidkar is an unique brand where the ancient traditional art forms that were almost consigned to oblivion are resurrected, and along with infusing a new lease of life to Centuries-old closely guarded secretive leather techniques that are met Futuristic Designs and needs. 

Crafting artisanal leather goods, the brand unveils and flaunts to the world, beaucoup distinctive leather weaving designs intrinsic to traditional artisans & leathersmiths, which instantly confers Lidkar it’s iconic look & distinctive style. 

</p>
<h1 className='text-2xl font-helvetica mb-4'>Quality : Timeless, Eternal</h1>
<p className='mb-4   text-[14px] text-justify'>Many of the artisans still deploy/utilize/use closely-guarded secretive traditional methods/art of leather processing that’s been around for centuries/eons and usually, passed down within the family as a heirloom. The result is the exquisite natural texture, fine quality, par excellence tensile strength and a durability that defies the sands of time just like Ötzi's shoes.</p>
<h1 className='text-2xl font-helvetica mb-4'>Becoming “The Brand”</h1>
  <p className='mb-4   text-[14px] text-justify'>The salient "Leather is Legendary, Leather is Luxury, Leather is Lidkar" became the brand's historic/fabled 3L slogan because the handcrafted Lidkar's products are immediately recognizable, and till recently, its logo only appeared discreetly on the inside of its products.

</p>
    </div>
  )
}

export default BrandLidkar