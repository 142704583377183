import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";

const MoreInfoTab = () => {
  return (
    <>
      <div className="p-1 transition-all text-sm duration-1000 ease-in-out">
        <ul className="list-disc pl-5 space-y-1">
          <li>
            Manufactured and Packed by: LIDKAR Private Limited; Central Stores Lidkar, Bengaluru, Lidkar Colony, Kadugondanahalli, Bengaluru, Karnataka - 560045
          </li>
          <li>Country of Origin: India</li>
          <li>
            Customer Care (Email/Ph/Address): 
            <a href="mailto:customercare@lidkar.com" className="text-blue-500 hover:underline">customercare@lidkar.com</a> / +91 84477 46959
            <br />
            No. 32, Dr. Babu Jagjivan Ram Lidkar Bhavana, Millers Bund Area, Vasantha Nagara, Bengaluru - 560052
            <br />
            <a href="mailto:customercare@lidkar.com" className="text-blue-500 hover:underline">customercare@lidkar.com</a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MoreInfoTab;
