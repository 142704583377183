import React from 'react'
import ContactUs from '../components/ContactPage/ContactUs'

const ContactUsPage = () => {
  return (
    <div>
      <ContactUs/>
    </div>
  )
}

export default ContactUsPage
