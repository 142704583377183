import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
 
import { IoStar } from "react-icons/io5";

const RateProductDialog = () => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  const handleNext = () => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      // Handle form submission or any final actions
      setOpen(false);
    }
  };
 const ratingLabels = ["Horrible", "Bad", "Average", "Good", "Excellent"];
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <p className="w-[180px] h-[33px] border border-gray-300 text-blue-600 p-1 cursor-pointer">
          Rate Product
        </p>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[660px] p-6">
        <DialogHeader>
          {step === 1 && (
            <div className="text-center">
              <h1 className="text-base font-helvetica font-semibold">Rate the Product</h1>
              <p className="text-[14px] mb-4">How did you find this product based on your usage?</p>
              <div className="flex gap-6 justify-center">
                {[...Array(5)].map((star, index) => {
                  const ratingValue = index + 1;
                  return (
                    <div key={index} className="flex flex-col items-center">
              <label>
                <input
                  type="radio"
                  name="rating"
                  value={ratingValue}
                  onClick={() => setRating(ratingValue)}
                  className="hidden"
                />
                <IoStar
                  className={`cursor-pointer text-2xl ${
                    ratingValue <= (hover || rating)
                      ? 'text-yellow-500'
                      : 'text-gray-300'
                  }`}
                  onMouseEnter={() => setHover(ratingValue)}
                  onMouseLeave={() => setHover(0)}
                />
              </label>
              <span className="text-sm text-gray-700 mt-1">
                {ratingLabels[index]}
              </span>
            </div>

                  );
                })}
              </div>
            </div>
          )}
          {step === 2 && (
            <div>
              <h1 className="text-base font-helvetica">Additional Feedback</h1>
              <p className="text-[14px]">Please provide any additional comments or suggestions.</p>
              {/* Additional feedback content here */}
            </div>
          )}
        </DialogHeader>
       
          <button
            onClick={handleNext}
            className="flex justify-end items-center py-2 px-4  text-blue-600 rounded-md  focus:outline-none"
          >
            {step === 2 ? 'Submit' : 'Next'}
          </button>
        
      </DialogContent>
    </Dialog>
  );
};

export default RateProductDialog;
