import { Link } from "react-router-dom";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { updateCartItemBySize } from "../../services/operations/cartItemApi";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
const SelectSizeModel = ({ cartDetails, cartItemId, colorDTO, sizeDTO,fetchAgain }) => {

  const { token } = useSelector((state) => state.auth);
  const [selectSizeId, setSelectedSizeId] = useState(null);
  const [open, setOpen] = useState(false);

  var colorId = colorDTO.colorId;
  var sizeId = sizeDTO.sizeId;

  var singleCart = cartDetails?.filter(
    (cart) => cart.cartItemId === cartItemId
  );


  //   const onSelectSize = (sizeId) => {
  //     setSelectedSizeId(sizeId);
  //     handleSelectSize(sizeId);
  //   };
  const handleSelectSize = (sizeId) => {
    setSelectedSizeId(sizeId);
 
  };

  const handleSelectsize = () => {
    const fetchData = async () => {
      try {
        const response = await updateCartItemBySize(
          token,
          cartItemId,
          selectSizeId
        );
        fetchAgain()
        setOpen(false);
      
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
      }
    };
    fetchData();
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <p className="text-blue-500 text-lg text-right cursor-pointer">Size</p>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[560px] w-[600px]">
        <DialogHeader>
          <div>
            {singleCart?.map((cartData, index) => (
              <>
                <div className="flex justify-start items-center gap-12 w-fit">
                  <div className="w-[140px] h-[150px] bg-[#EFEFEF] rounded-md ">
                    <img
                      src={cartData?.productDTO?.productImage[0]?.imagePath}
                    />
                  </div>
                  <div className="">
                    <p className="text-[#636363] min-w-[200px] max-w-[200px] h-[40px]  font-prompt">
                      {cartData?.productDTO.categoryDTO.categoryName}
                    </p>
                    <p className="text-[#303030] font-orator text-[20px] overflow-hidden font-semibold">
                      {cartData?.productDTO?.productName}
                    </p>
                    <div className="flex gap-6">
                      <p className="text-[27px] text-[#000000] font-basicommercial font-bold">
                        ₹{cartData?.productDTO?.sellingPrice}
                      </p>
                      {cartData?.productDTO?.discount != 0 && (
                        <p className="text-[24px] text-[#514F4F] font-prompt">
                          MRP 
                          <span className="line-through text-red-500 font-basicommercial">
                            ₹{cartData?.productDTO?.mrpPrice}

                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-[450px] items-center justify-center mx-auto mt-3">
                  <hr className="mt-5" />
                  <h1 className="font-bold text-[#303030] text-[24px] font-helvetica mt-5">
                    Select Size
                  </h1>
                  <div className="flex items-center justify-start   gap-6 mt-3">
                    {cartData?.productDTO?.sizes?.map((size) => (
                      <p
                        key={size.sizeId}
                        className={`h-[41px] w-[41px] border rounded-full border-slate-500 text-[black] flex items-center justify-center cursor-pointer ${
                          size.sizeId === selectSizeId
                            ? "border-2 border-green-500"
                            : "border-slate-500"
                        }`}
                        onClick={() => handleSelectSize(size.sizeId)}
                      >
                        {size.sizeNumber}
                      </p>
                    ))}
                  </div>
                  <button
                    className="w-full mt-5 h-[30px] py-6 rounded-md bg-[#56243B] font-prompt text-[white] border border-[#56243B] flex items-center justify-center hover:bg-[white] hover:text-[black]"
                    onClick={handleSelectsize}
                  >
                    DONE
                  </button>
                </div>
              </>
            ))}
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default SelectSizeModel;
