// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { useForm } from "react-hook-form";
// import { useDispatch } from "react-redux";

// import { Button } from "../../ui/button";
// import { z } from "zod";
// import { signUp } from "../../../services/operations/authApi";
// import toast from "react-hot-toast";

// const Signup = () => {
//   const dispatch = useDispatch();
//   const form = useForm({ mode: "onChange" });
//   const [formSubmitted, setFormSubmitted] = useState(false);
//   const [emptyFields, setEmptyFields] = useState([]);
//   const [firstName, setFirstName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [emailID, setEmailID] = useState("");
//   const [password, setPassword] = useState("");

//   const [formErrors, setFormErrors] = useState({
//     firstName: "",
//     lastName: "",
//     phoneNumber: "",
//     emailID: "",
//     password: "",
//   });

//   const emailRegex = /^[\w-]+(?:\.[\w-]+)*@(?:[a-zA-Z0-9]+\.)+(?:com|in)$/;

//   const onSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       if (!emailID && !firstName && !lastName && !phoneNumber && !password) {
//         toast.error("All Fields Are Required");
//         return;
//       }
//       const errors = {};
//       if (!firstName.trim()) {
//         toast.error("First Name Should Not be Empty");
//         return;
//       }
//       if (!lastName.trim()) {
//         toast.error("Last Name Should Not Be Empty");
//         return;
//       }
//       if (!phoneNumber.trim()) {
//         toast.error("Phone Number Should Not Be Empty");
//         return;
//       } else if (!/^[6-9]\d{9}$/.test(phoneNumber)) {
//         toast.error("Invalid Phone Number");
//         return;
//       }
//       if (!emailID.trim()) {
//         toast.error("Email should not be empty");
//         return;
//       } else if (!emailRegex.test(emailID)) {
//         toast.error("Email must end with .com or .in");
//         return;
//       }
//       if (!password.trim()) {
//         toast.error("Password is Required");
//         return;
//       }
//       if (password.length < 8) {
//         toast.error("Password should be more than 8 Characters");
//         return;
//       }

//       if (Object.keys(errors).length > 0) {
//         setFormErrors(errors);
//         return;
//       }

//       dispatch(signUp(firstName, lastName, phoneNumber, emailID, password));
//       setFormSubmitted(true);
//       setFirstName("");
//       setLastName("");
//       setPhoneNumber("");
//       setEmailID("");
//       setPassword("");
//     } catch (error) {
//       console.error(error);
//       setFormSubmitted(false);
//     }
//   };

//   useEffect(() => {
//     if (formSubmitted) {
//       form.reset();
//       setFormSubmitted(false);
//     }
//   }, [formSubmitted]);

//   return (
//     <>
//       <h1 className="font-semibold text-center text-2xl mt-5 mb-4 md:text-3xl text-gray-700">
//         Sign up
//       </h1>
//       <p className="flex mx-auto items-center justify-center text-center w-[800px] text-base md:text-lg text-black ">
//         Your personal data will be used to support your experience throughout
//         this website, to manage access to your account, and for other purposes
//         described in our
//       </p>
//       <div className="mx-auto max-w-xl mb-3 mt-3 p-3">
//         <div className="bg-white border border-gray-300 rounded-lg p-6 mt-2 mx-auto inline-block w-full">
//           <form onSubmit={onSubmit} className="space-y-4">
//             <div className="flex justify-between gap-3">
//               <div className="flex flex-col flex-1">
//                 <label className="font-semibold text-base text-gray-700 mb-4">
//                   First Name
//                 </label>
//                 <input
//                   type="text"
//                   value={firstName}
//                   onChange={(e) => setFirstName(e.target.value)}
//                   className="rounded-full bg-gray-200 p-2 placeholder-gray-400 pl-4"
//                   placeholder="First Name"
//                 />
//                 {!firstName && (
//                   <small className="text-red-500">{formErrors.firstName}</small>
//                 )}
//               </div>
//               <div className="flex flex-col flex-1">
//                 <label className="font-semibold text-base text-gray-700 mb-4">
//                   Last Name
//                 </label>
//                 <input
//                   type="text"
//                   value={lastName}
//                   onChange={(e) => setLastName(e.target.value)}
//                   className="rounded-full bg-gray-200 p-2 placeholder-gray-400 pl-4"
//                   placeholder="Last Name"
//                 />
//                 {!lastName && (
//                   <small className="text-red-500">{formErrors.lastName}</small>
//                 )}
//               </div>
//             </div>
//             <div className="flex flex-col">
//               <label className="font-semibold text-base text-gray-700 mb-4">
//                 Phone Number
//               </label>
//               <input
//                 type="number"
//                 value={phoneNumber}
//                 onChange={(e) => {
//                   const enteredValue = e.target.value;

//                   if (/^\d{0,10}$/.test(enteredValue)) {
//                     setPhoneNumber(enteredValue);
//                   }
//                 }}
//                 className="rounded-full bg-gray-200 p-2 placeholder-gray-400 pl-4"
//                 placeholder="Phone Number"
//               />
//               {!phoneNumber && (
//                 <small className="text-red-500">{formErrors.phoneNumber}</small>
//               )}
//             </div>
//             <div className="flex flex-col">
//               <label className="font-semibold text-base text-gray-700 mb-4">
//                 Email
//               </label>
//               <input
//                 type="email"
//                 value={emailID}
//                 onChange={(e) => setEmailID(e.target.value)}
//                 className="rounded-full bg-gray-200 p-2 placeholder-gray-400 pl-4"
//                 placeholder="Email"
//               />
//               {!emailID && (
//                 <small className="text-red-500">{formErrors.emailID}</small>
//               )}

//               {/* emailID */}
//             </div>
//             <div className="flex flex-col">
//               <label className="font-semibold text-base text-gray-700 mb-4">
//                 Password
//               </label>
//               <input
//                 type="password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 className="rounded-full bg-gray-200 p-2 placeholder-gray-400 pl-4"
//                 placeholder="Password"
//               />
//               {!password && (
//                 <small className="text-red-500">{formErrors.password}</small>
//               )}
//             </div>

//             <Button
//               type="submit"
//               className="bg-[#552038] hover:bg-[#411b2d] w-full px-2 py-2 rounded-[15px] mt-4 text-white"
//             >
//               Register
//             </Button>
//           </form>

//           <p className="text-center mt-5">
//             Existing User?
//             <Link to={"/login"} className="text-blue-500">
//               {" "}
//               Sign in
//             </Link>
//           </p>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Signup;

import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Button } from "../../ui/button";
import { signUp } from "../../../services/operations/authApi";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Signup = () => {
  const dispatch = useDispatch();
  const form = useForm({ mode: "onChange" });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailID, setEmailID] = useState("");
  const [password, setPassword] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [firstNamePlaceHolder, setFirstNamePlaceHolder] = useState("First Name");
  const [lastNamePlaceHolder, setLastNamePlaceHolder] = useState("Last Name");
  const [emailPlaceHolder, setEmailPlaceHolder] = useState("Email");
  const [phoneNumberPlaceHolder, setPhoneNumberPlaceHolder] =
    useState("Mobile Number");
  const [passwordPlaceHolder, setPasswordPlaceHolder] = useState("Password");
  const [typingField, setTypingField] = useState(null);
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailID: "",
    password: "",
  });
  const firstInputRef = useRef(null);

  const emailRegex = /^[\w-]+(?:\.[\w-]+)*@(?:[a-zA-Z0-9]+\.)+(?:com|in)$/;

  const validateForm = () => {
    const errors = {};

    if (!firstName.trim()) {
      errors.firstName = "First Name Required";
      return errors
      
    } else if (firstName.length > 50) {
      errors.firstName = "First Name should not exceed 50 characters";
      return errors
      
    }

    if (!lastName.trim()) {
      errors.lastName = "Last Name Required";
      return errors
    } else if (lastName.length > 50) {
      errors.lastName = "Last Name should not exceed 50 characters";
      return errors
    }

    if (!phoneNumber.trim()) {
      errors.phoneNumber = "Mobile  Number Required";
      return errors
    } else if (!/^[6-9]\d{9}$/.test(phoneNumber)) {
      errors.phoneNumber = "Invalid Mobile Number";
      return errors
    }
    if (!emailID.trim()) {
      errors.emailID = "Email Required";
      return errors
    } else if (!emailRegex.test(emailID)) {
      errors.emailID = "Enter Valid Email Id";
      return errors
    }

    if (!password.trim()) {
      errors.password = "Password is Required";
      return errors
    } else if (password.length < 8) {
      errors.password = "Password Should Be More Than 8 Characters";
      return errors
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.password = "Password must contain a special character";
      return errors;
    } else if (!/[a-zA-Z]/.test(password)) {
      errors.password = "Password must contain alphabets";
      return errors;
    }

    return errors;
  };
  useEffect(() => {
    firstInputRef.current.focus();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    try {
      dispatch(signUp(firstName, lastName, phoneNumber, emailID, password));
      setFormSubmitted(true);
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setEmailID("");
      setPassword("");
      setFormErrors({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailID: "",
        password: "",
      });
    } catch (error) {
      console.error(error);
      setFormSubmitted(false);
    }
  };
  
  useEffect(() => {
    if (formSubmitted) {
      form.reset();
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  const showFirstError = () => {
    for (const field in formErrors) {
      if (formErrors[field]) {
        return formErrors[field];
      }
    }
    return null;
  };
  const handlePhoneNumberChange = (value) => {
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value.replace(/\D/g, ""));
      handleTyping("phoneNumber");
    }
  };

  const handleTyping = (field) => {
    setTypingField(field);
    setFormErrors(() => ({
      [field]: "",
    }));
  };
  const handleBlur = (placeholderSetter, value, initialValue) => {
    if (!value) {
      placeholderSetter(initialValue);
    }

    // const emailError = validateEmail(value);
    // if (emailError) {
    //   setFormErrors((prevErrors) => ({ ...prevErrors, emailID: emailError }));
    // } else {
    //   setFormErrors((prevErrors) => {
    //     const { emailID, ...otherErrors } = prevErrors;
    //     return otherErrors;
    //   });
    // }
  };

  const handleFocus = (placeholderSetter) => {
    placeholderSetter("");
  };
  return (
    <>
      <h1 className="text-2xl mt-1 p-2 font-helvetica text-center  mb-1 md:text-3xl text-black-700">
        Register
      </h1>
      <div className="mx-auto max-w-xl mb-2 mt-1 p-1">
      <div className="bg-white border border-gray-300 rounded-lg p-4 mt-2 mx-auto w-full">
        <form onSubmit={onSubmit} className="space-y-4">
          <div className="flex justify-between gap-3">
            <div className="flex flex-col flex-1">
              <label className="font-semibold text-base text-gray-700 mb-1">
                First Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={firstName}
                maxLength="50"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  handleTyping('firstName');
                }}
                ref={firstInputRef}
                onFocus={() => handleFocus(setFirstNamePlaceHolder)}
                onBlur={() => handleBlur(setFirstNamePlaceHolder, firstName, 'First Name')}
                className={`rounded-[7px] bg-white ${
                  showFirstError() === formErrors.firstName ? 'border-red-500' : 'border-gray-300'
                } border p-2 placeholder-gray-400 pl-4 focus:outline-none focus:border-[#552038]`}
                placeholder="First Name"
              />
              {showFirstError('firstName') && (
                <small className="text-red-500">{formErrors.firstName}</small>
              )}
              {typingField === 'firstName' && (
                <small className="text-gray-500 self-end">
                  ({firstName.length}/50)
                </small>
              )}
            </div>
            <div className="flex flex-col flex-1">
              <label className="font-semibold text-base text-gray-700 mb-1">
                Last Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={lastName}
                maxLength="50"
                onChange={(e) => {
                  setLastName(e.target.value);
                  handleTyping('lastName');
                }}
                onFocus={() => handleFocus(setLastNamePlaceHolder)}
                onBlur={() => handleBlur(setLastNamePlaceHolder, lastName, 'Last Name')}
                className={`rounded-[7px] bg-white ${
                  showFirstError() === formErrors.lastName ? 'border-red-500' : 'border-gray-300'
                } border p-2 placeholder-gray-400 pl-4 focus:outline-none focus:border-[#552038]`}
                placeholder="Last Name"
              />
              {showFirstError('lastName') && (
                <small className="text-red-500">{formErrors.lastName}</small>
              )}
              {typingField === 'lastName' && (
                <small className="text-gray-500 self-end">
                  ({lastName.length}/50)
                </small>
              )}
            </div>
          </div>

          <div className="flex flex-col">
            <label className="font-semibold text-base text-gray-700 mb-1">
              Mobile Number <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              maxLength="10"
              value={phoneNumber}
              onKeyDown={(e) => {
                const keyCode = e.keyCode || e.which;
                if (
                  !(
                    (keyCode >= 48 && keyCode <= 57) ||
                    (keyCode >= 96 && keyCode <= 105) ||
                    keyCode === 8 ||
                    keyCode === 9 ||
                    keyCode === 37 ||
                    keyCode === 39 ||
                    keyCode === 46 ||
                    keyCode === 110 ||
                    keyCode === 190
                  )
                ) {
                  e.preventDefault();
                  setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    phoneNumber: 'Mobile number must contain only digits',
                  }));
                } else {
                  setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    phoneNumber: '',
                  }));
                }
              }}
              onChange={(e) => {
                handlePhoneNumberChange(e.target.value);
              }}
              onFocus={() => handleFocus(setPhoneNumberPlaceHolder)}
              onBlur={() =>
                handleBlur(setPhoneNumberPlaceHolder, phoneNumber, 'Mobile Number')
              }
              className={`rounded-[7px] bg-white ${
                showFirstError() === formErrors.phoneNumber ? 'border-red-500' : 'border-gray-300'
              } border p-2 placeholder-gray-400 pl-4 focus:outline-none focus:border-[#552038]`}
              placeholder="Mobile Number"
            />
            {showFirstError('phoneNumber') && (
              <small className="text-red-500">{formErrors.phoneNumber}</small>
            )}
          </div>

          <div className="flex flex-col">
            <label className="font-semibold text-base text-gray-700 mb-1">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              value={emailID}
              onChange={(e) => {
                setEmailID(e.target.value);
              }}
              onFocus={() => handleFocus(setEmailPlaceHolder)}
              onBlur={() => handleBlur(setEmailPlaceHolder, emailID, 'Email')}
              className={`rounded-[7px] bg-white ${
                showFirstError()  ? 'border-red-500' : 'border-gray-300'
              } border p-2 placeholder-gray-400 pl-4 focus:outline-none focus:border-[#552038]`}
              placeholder="Email"
            />
            {showFirstError('emailID') && (
              <small className="text-red-500">{formErrors.emailID}</small>
            )}
          </div>

          <div className="flex relative flex-col">
            <label className="font-semibold text-base text-gray-700 mb-1">
              Password <span className="text-red-500">*</span>
            </label>
            <div className="w-full relative">
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                maxLength="20"
                onChange={(e) => {
                  setPassword(e.target.value);
                  handleTyping('password');
                }}
                onFocus={() => handleFocus(setPasswordPlaceHolder)}
                onBlur={() => handleBlur(setPasswordPlaceHolder, password, 'Password')}
                className={`rounded-[7px] bg-white ${
                  showFirstError() === formErrors.password ? 'border-red-500' : 'border-gray-300'
                } border p-2 placeholder-gray-400 w-full pl-4 pr-10 focus:outline-none focus:border-[#552038]`}
                placeholder="Password"
              />
              <div className="absolute right-3 top-5 transform -translate-y-1/2 cursor-pointer">
                {showPassword ? (
                 
                  <FaEye
                    onClick={() => setShowPassword(false)}
                    className="text-gray-400 mb-0"
                  />
                ) : (
                  <FaEyeSlash
                    onClick={() => setShowPassword(true)}
                    className="text-gray-400 mb-0"
                  />
                )}
              </div>
              {showFirstError('password') && (
                <small className="text-red-500">{formErrors.password}</small>
              )}
            </div>
          </div>
          <button
            type="submit"
            className="bg-[#F9C88F] hover:bg-[#F6B97B] w-full px-2 py-2 rounded-[15px] mt-2 text-black"
          >
            Register
          </button>
        </form>

        <p className="text-center mt-1">
          Existing User?{' '}
          <Link
            to="/login"
            onClick={() => window.scrollTo(0, 0)}
            className="text-blue-500 hover:text-blue-900 hover:font-semibold"
          >
            Login
          </Link>
        </p>
      </div>
    </div>

    </>
  );
};

export default Signup;
