import React from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";


const AllProductPage = ({categoriesData}) => {

  return (
    <div className="w-1920 px-[5rem] py-3">
      <p className="font-prompt text-[20px] text-[#000000]">
        All Categories -<span className="text-[#636363]">{" "}{categoriesData?.length} Items</span>{" "}
      </p>
      <div className="flex justify-between items-center mb-3">
        <p className="text-[#000000] text-[20px]">Filters</p>
        <div className="flex justify-center items-center gap-5">
          <p>Sort By</p>
          <button className="text-[16px] text-[#636363] border border-grey px-2 py-2">
            Popularity
          </button>
          <button className="text-[16px] text-[#636363] border border-grey px-2 py-2">
            Date
          </button>
          <Select>
            <SelectTrigger className="w-[70px]">
              <SelectValue placeholder="Price" />
            </SelectTrigger>
            <SelectContent>
              {/* <SelectGroup>
          <SelectLabel>Fruits</SelectLabel>
          <SelectItem value="apple">Apple</SelectItem>
          <SelectItem value="banana">Banana</SelectItem>
          <SelectItem value="blueberry">Blueberry</SelectItem>
          <SelectItem value="grapes">Grapes</SelectItem>
          <SelectItem value="pineapple">Pineapple</SelectItem>
        </SelectGroup> */}
            </SelectContent>
          </Select>
          <button className="text-[16px] text-[#636363] border border-grey px-2 py-2">
            Review
          </button>
        </div>
        <p className="text-[20px] text-[#636363]">
          Showing 1 - 16 of 98 results
        </p>
      </div>
      <hr />
    </div>
  );
};

export default AllProductPage;
