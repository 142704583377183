import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Hero from "../components/Home/Hero";
import TopBar from "../components/Home/TopBar";
import Footer from "./Footer";

import Categories from "../components/Home/Categories/Categories";
import FlashSale from "../components/Home/Flashsale/FlashSale";
import Banner from "../components/Home/Banner";
import IntroducingProducts from "../components/Home/IntroducingProducts";
import ExploreNew from "../components/Home/ExploreNew/ExploreNew";
import FeaturedProduct from "../components/Home/FeaturedProduct/FeaturedProduct";
import { getAllProducts } from "../services/operations/productApi";
import UpArrow from "../components/Home/UpArrow";
import MobileCategories from "../components/Home/Categories/MobileCategories";
import NewArrival from "../components/Home/NewArrivals/NewArrival";
import TrendyPicks from "../components/TrendyPicks/TrendyPicks";
const HomePage = () => {
  const [products, setAllProducts] = useState([]);
  const [showArrow, setShowArrow] = useState(false);

  const fetAllProducts = async () => {
    try {
      const response = await getAllProducts();
  
      setAllProducts(response.slice(0, 8));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetAllProducts();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <Hero />
        <MobileCategories/>
      <Categories />
      {/* <FlashSale /> */}
     
      {/* <Banner /> */}
      <TrendyPicks/>
      <NewArrival/>
      <FeaturedProduct products={products} />
   

      <IntroducingProducts />

       
      <ExploreNew />
      {showArrow && (
        <div className="fixed bottom-[30px] right-[25px] z-50">
          <UpArrow onClick={scrollToTop} />
        </div>
      )}
    </div>
  );
};

export default HomePage;
